import React, { useEffect, useState } from 'react'
import { Autocomplete, Box, Button, CircularProgress, FormControl, Grid, InputLabel, Modal, TextField, Typography } from '@mui/material'
import './AddPhysician.css'
import { Controller, useForm } from 'react-hook-form'
import Input from '../../../Components/InputComponent/Input'
import ButtonComponent from '../../../Components/ButtonComponent/ButtonComponent'
import { GetApi, GetApiParam, postApi, updateApi } from '../../../Api/Api'
import toast from 'react-hot-toast'

const AddPhysician = (props) => {
  const { handleClose, open, editValue, physicianId, AddUpdatePhysician, hospitalData } = props;
  const [roleData, setRoleData] = useState([]);
  const [loading, setLoading] = useState(true);
  // const [error, setError] = useState(null);
  const [hospitalDetails, setHospitalDetails] = useState(null);
  const[roleDetails, setRoleDetails] = useState(null);

  const style = {
    position: 'absolute',
    top: '50%',
    left: 15,
    right: 15,
    transform: 'translateY(-50%)',
    maxWidth: 1000,
    color: '#ffffff',
    boxShadow: 24,
    margin: '1rem auto',
    maxHeight: "100%"
  };

  const {
    register,
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm({

  })

 
  useEffect(() => {
    if (editValue) {

      const fetchPhysicianDetails = async () => {
        const endpoint = '/physician/'

        try {
          const response = await GetApiParam(endpoint, { id: physicianId })
          const physicianDetails = response.data.data[0];
          console.log(physicianDetails, 'physicianDetails--------------------')
          setValue('title', physicianDetails.title);
          setValue('first_name', physicianDetails.first_name);
          setValue('middle_name', physicianDetails.middle_name);
          setValue('last_name', physicianDetails.last_name);
          setValue('phone_no', physicianDetails.phone_no);
          setValue('email_id', physicianDetails.email_id);

          setHospitalDetails(hospitalData.find((hospital) => hospital.id === physicianDetails.hospital_id))
          setRoleDetails(roleData.find((role) => role.id === physicianDetails.role_id) || null);

          // setValue('hospital_id', hospitalDetails.hospital_name);
          // setValue('role_id', roleDetails);
        } catch (error) {
          console.error('Error fetching physician details:', error);
        }
      };

      fetchPhysicianDetails();
    }
  }, [editValue, physicianId, hospitalData, roleData, setValue]);
  console.log(hospitalDetails, '--------------------------------hospital s')


  //Fetch Roles
  const fetchRoleData = async () => {
    const endpoint = '/role/';
    try {
      const response = await GetApi(endpoint);
      console.log(response, 'response------------------');
      setRoleData(response.data.data);
      setLoading(false);
    } catch (error) {
      // setError(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRoleData();
  }, []);

  if (loading) {
    return <CircularProgress style={{ margin: '20px' }} />;
  }

  const onSubmit = async (data, event) => {
    event.preventDefault();
    const endpoint = editValue ? `/physician/${physicianId}` : '/physician/';
    const apiFunction = editValue ? updateApi : postApi;

    try {
      const responseData = await apiFunction(endpoint, editValue ? { ...data, id: physicianId } : data);
      console.log('API response:', responseData.data);

      if (editValue ? responseData.data : responseData.data.data) {
        console.log(editValue ? 'Physician Updated' : 'Physician Added');
        toast.success(editValue ? "Physician Updated" : "Physician Added");
        reset();
        handleClose();
        AddUpdatePhysician()
      } else {
        console.log('User is not authenticated');
        toast.error(editValue ? "Physician Updated Failed" : "Physician Added Failed");
      }
    } catch (error) {
      console.log('Error:', error);
    }
  };


  return (
    <Box >
      <Modal
        open={open}
        onClose={handleClose}
        className="profile-modal"
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} >
          <Box className="modal-shadow">
            <Box className="modal-header" >
              <Typography id="modal-modal-title" className='text-primery' variant="h4" component="h4">
                Add Physician
              </Typography>
              <Button onClick={handleClose}>
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                  <path opacity="0.2" d="M32.0002 3.138L28.8632 0L16.4242 12.439L3.98517 0L0.847168 3.138L13.2862 15.577L0.847168 28.016L3.98517 31.154L16.4242 18.714L28.8632 31.153L32.0012 28.015L19.5612 15.577L32.0002 3.138Z" fill="white" />
                </svg>
              </Button>
            </Box>
            <Box className="modal-body" id="modal-modal-description" component="form" onSubmit={handleSubmit(onSubmit)}>
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6}>
                    <Box className="form-group">
                      <Input
                        label="Title"
                        placeholder="Mr / Ms"
                        type="text"
                        name="title"
                        requiredFeild="requiredFeild"
                        register={register}
                        validationRules={{
                          required: 'Title is required',
                        }} />
                      {errors.title && <p className="error-message">{errors.title.message}</p>}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Box className="form-group">
                      <Input
                        label="First Name"
                        placeholder="Lindsey"
                        type="text"
                        name="first_name"
                        requiredFeild="requiredFeild"
                        register={register}
                        validationRules={{
                          required: 'First Name is required',
                        }} />
                      {errors.first_name && <p className="error-message">{errors.first_name.message}</p>}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Box className="form-group">
                      <Input
                        label="Middle Name"
                        placeholder="Daniel"
                        type="text"
                        name="middle_name"
                        requiredFeild="requiredFeild"
                        register={register}
                        validationRules={{
                          required: 'Middle Name is required',
                        }} />
                      {errors.middle_name && <p className="error-message">{errors.middle_name.message}</p>}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Box className="form-group">
                      <Input
                        label="Last Name"
                        placeholder="Thomas"
                        type="text"
                        name="last_name"
                        requiredFeild="requiredFeild"
                        register={register}
                        validationRules={{
                          required: 'Last Name is required',
                        }} />
                      {errors.last_name && <p className="error-message">{errors.last_name.message}</p>}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Box className="form-group">
                      <Input
                        label="Phone Number"
                        placeholder="963214587"
                        type="text"
                        name="phone_no"
                        requiredFeild="requiredFeild"
                        register={register}
                        validationRules={{
                          required: 'Phone Number is required',
                          pattern: {
                            value: /^\d{10}$/,
                            message: 'Please enter a valid 10-digit Phone Number'
                          }
                        }}
                      />
                      {errors.phone_no && <p className="error-message">{errors.phone_no.message}</p>}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Box className="form-group">
                      <Input
                        label="Email"
                        placeholder="apollo@gmail.com"
                        type="email"
                        name="email_id"
                        requiredFeild="requiredFeild"
                        register={register}
                        validationRules={{
                          required: 'Email is required',
                          pattern: {
                            value: /^\S+@\S+$/i,
                            message: 'Please enter a valid email address'
                          }
                        }}
                      />
                      {errors.email_id && <p className="error-message">{errors.email_id.message}</p>}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <Box className="form-group">
                      <Box className="select-opation--wrapper">
                        <FormControl variant="standard">
                          <InputLabel shrink className='input-label'>Hospital Id</InputLabel>
                          <Controller
                            name="hospital_id"
                            control={control}
                            defaultValue={hospitalDetails ? hospitalDetails.hospital_name : ""}
                            render={({ field }) => (
                              <Autocomplete
                                {...field}
                                disablePortal
                                id="combo-box-demo"
                                options={hospitalData}
                                getOptionLabel={(hospital) => hospital.hospital_name || ''}
                                sx={{ width: '100%' }}
                                renderInput={(params) => <TextField placeholder={hospitalDetails ? hospitalDetails.hospital_name : ""} {...params} />}
                                onChange={(_, value) => setValue('hospital_id', value ? value.id : '')}
                                value={hospitalData.find((hospital) => hospital.id === watch('hospital_id')) || null}
                                key={hospitalDetails ? hospitalDetails.hospital_name : ""}
                                defaultValue={hospitalDetails ? hospitalDetails.hospital_name : ""}
                              />
                            )}
                          />
                        </FormControl>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <Box className="form-group">
                      <Box className="select-opation--wrapper">
                        <FormControl variant="standard">
                          <InputLabel shrink className='input-label'>Role Id</InputLabel>
                          <Controller
                            name="role_id"
                            control={control}
                            // defaultValue=""
                            render={({ field }) => (
                              <Autocomplete
                                {...field}
                                disablePortal
                                id="combo-box-demo"
                                options={roleData}
                                getOptionLabel={(role) => role.name || ''}
                                sx={{ width: '100%' }}
                                renderInput={(params) => <TextField placeholder={roleDetails? roleDetails.name :""} {...params} />}
                                onChange={(_, value) => setValue('role_id', value ? value.id : '')}
                                value={roleData.find((role) => role.id === field.value) || null}
                              />
                            )}
                          />
                        </FormControl>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Box className="form-group">
                      <Box className="btn-center">
                        <ButtonComponent type="submit" buttonText={editValue? 'Update Physician':'Add Physician'} buttonVariant="contained" />
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>


    </Box>
  )
}

export default AddPhysician