import React, { useEffect, useState } from "react";
import "./MyPatients.css";
import {
  Box,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/material";
import ButtonComponent from "../../Components/ButtonComponent/ButtonComponent";
import AddIcon from "@mui/icons-material/Add";
import ListOutlinedIcon from "@mui/icons-material/ListOutlined";

import { GetApi } from "../../Api/Api";
import { PatientTable, AddPatients } from "./components";
import { Toaster } from "react-hot-toast";

const MyPatients = () => {
  const [open, setOpen] = useState(false);
  const [patientData, setPatientData] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchPatientData = async () => {
    const endpoint = "/patients";
    try {
      const response = await GetApi(endpoint);
      setPatientData(response.data.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPatientData();
  }, []);

  const AddUpdatePatient = () => {
    fetchPatientData();
  };

  if (loading) {
    return <CircularProgress style={{ margin: "20px" }} />;
  }

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box>
      <Toaster position="top-right" reverseOrder={false} />
      <Box className="my-patients-topbar" mt={3}>
        <Box>
          <Typography className="title" component="h4">
            Patients
          </Typography>
        </Box>
        <Box className="right btn-font-small">
          <Stack direction="row" spacing={{ xs: 1, md: 2 }}>
            <ButtonComponent
              buttonText="Add Patient"
              buttonVariant="contained"
              startIcon={<AddIcon />}
              buttonStyle={{ borderRadius: "30px" }}
              onClick={handleOpen}
            />
            <ButtonComponent
              buttonText={<ListOutlinedIcon />}
              buttonVariant="outline"
              buttonStyle={{ borderRadius: "10px" }}
              buttonClass="btn-small only-icon-btn"
            />
          </Stack>
        </Box>
      </Box>
      <Box sx={{ mt: "30px" }}>
        <PatientTable
          patientData={patientData}
          AddUpdatePatient={AddUpdatePatient}
        />
      </Box>
      <AddPatients
        open={open}
        handleClose={handleClose}
        AddUpdatePatient={AddUpdatePatient}
      />
    </Box>
  );
};

export default MyPatients;
