import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../Context/AuthContext';
import { basePath } from './Config';

const LoginRoutes = ({ component: Component, roles, ...rest }) => {
  const navigate = useNavigate();
  const { user } = useAuth();

  useEffect(() => {
    const token = localStorage.getItem('token');
    const user = localStorage.getItem('user');
  
    if (token) {
      navigate(basePath);
    }
    
    //  if (user) {
    //   navigate(basePath);
    // }

    // if (token && rest.path === '/PercyApp_Frontend/login') {
    //   return <Navigate to="/PercyApp_Frontend/" />;
    // }

    // if (roles && user && !roles.includes(user.role)) {
    //   navigate('/PercyApp_Frontend/unauthorized');
    // }


  }, [user, navigate]);

  return (
    <Component />
  );
};

export default LoginRoutes;
