import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Box,
  Stack,
  Switch,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import AddUser from "../Add user/AddUser";
import ConfirmationDialog from "../../../Components/ConfirmationDialog";
import { updateApi } from "../../../Api/Api";
import { useSearch } from "../../../Context/SearchContext";
import toast from "react-hot-toast";

const UserTable = ({ userData, AddUpdateUser }) => {
  const [open, setOpen] = useState(false);
  const [editValue, setEditValue] = useState(false);
  const [activeStatus, setActiveStatus] = useState(false);
  const [userId, setUserId] = useState();
  const [switchStates, setSwitchStates] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const { searchQuery } = useSearch();
  const [filteredData, setFilteredData] = useState(userData);

  useEffect(() => {
    if (searchQuery) {
      const filteredResults = userData.filter((item) =>
        Object.values(item).some((value) => String(value).includes(searchQuery))
      );
      setFilteredData(filteredResults);
    } else {
      setFilteredData([]);
    }
  }, [searchQuery, userData]);

  const displayData = searchQuery ? filteredData : userData;

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#042835",
      color: "rgb(255,255, 255, 70%)",
      borderBottom: "1px solid #021A22",
      fontFamily: "Acumin Pro",
      fontWeight: 700,
      fontSize: 16,
    },
    [`&.${tableCellClasses.body}`]: {
      // fontSize: 14,
      backgroundColor: "#03212b",
      color: "rgb(255,255, 255, 40%)",
      borderBottom: "1px solid #021A22",
      fontFamily: "Acumin Pro",
      fontSize: 16,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const handleOpen = (id) => {
    setEditValue(true);
    setUserId(id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEditValue(false);
    setUserId(null);
  };

  const handleSwitchChange = (id, currentSwitchState) => {
    setEditValue(true);
    setUserId(id);
    setActiveStatus(currentSwitchState)
    setSwitchStates((prevState) => ({
      ...prevState,
      [id]: !currentSwitchState,
    }));
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleDelete = (id) => {
    setUserId(id);
    setEditValue(false);
    setOpenDialog(true);
  };

  const handleUpdate = async () => {
    setOpenDialog(false);
    const endpoint = `/user/${userId}`;

    try {
      const requestData = editValue
        ? { is_actived: switchStates[userId] }
        : { is_deleted: true };

      const responseData = await updateApi(endpoint, requestData);

      console.log("responseData", responseData.data);
      if (responseData.data) {
        AddUpdateUser();
        toast.success(editValue ? "User Updated" : "User Deleted");
      } else {
        console.log("Update/Delete failed");
        toast.error(editValue ? "User Updated Failed" : "User Deleted Failed");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <Box className="table-container">
      <TableContainer component={Paper}>
        <Table style={{ borderCollapse: "collapse", minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <StyledTableCell>First Name</StyledTableCell>
              <StyledTableCell>Middle Name</StyledTableCell>
              <StyledTableCell>Last Name</StyledTableCell>
              <StyledTableCell>Email</StyledTableCell>
              <StyledTableCell>Phone Number</StyledTableCell>
              <StyledTableCell>Status</StyledTableCell>
              <StyledTableCell>Actions</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {displayData.map((user, index) => (
              <StyledTableRow key={user.id}>
                <StyledTableCell component="th" scope="row">
                  {user.first_name}
                </StyledTableCell>
                <StyledTableCell>{user.middle_name}</StyledTableCell>
                <StyledTableCell>{user.last_name}</StyledTableCell>
                <StyledTableCell>{user.email_id}</StyledTableCell>
                <StyledTableCell>{user.phone_no}</StyledTableCell>
                <StyledTableCell>
                  <Switch
                    checked={user.is_actived}
                    className="cstm-toggle-btn"
                    onChange={() =>
                      handleSwitchChange(user.id, user.is_actived)
                    }
                  />
                </StyledTableCell>
                <StyledTableCell>
                  {/* <Link to={`${user.id}`}> */}
                  <Stack
                    direction="row"
                    gap={2}
                    sx={{ justifyContent: "center" }}
                  >
                    <Button
                      variant="contained"
                      color="info"
                      className="btn-primary"
                      onClick={() => handleOpen(user.id)}
                    >
                      Edit
                    </Button>
                    <Button
                      variant="contained"
                      color="error"
                      className="btn-delete"
                      onClick={() => handleDelete(user.id)}
                    >
                      Delete
                    </Button>
                  </Stack>
                  {/* </Link> */}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <ConfirmationDialog
        open={openDialog}
        content={`Are you sure you really want to ${
          editValue ? (!activeStatus ? "active" : "deactive") : "Delete"
        } this ?`}
        onConfirm={handleUpdate}
        onClose={handleDialogClose}
      />
      <AddUser
        open={open}
        handleClose={handleClose}
        editValue={editValue}
        userId={userId}
        AddUpdateUser={AddUpdateUser}
      />
    </Box>
  );
};

export default UserTable;
