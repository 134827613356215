
import axios from 'axios';
import { GetApi } from './Api';

// const REACT_APP_BACKEND_URL = "http://52.87.56.163/stagebck/api"
// base url is passed inside create
const axiosInstance = axios.create(
  { baseURL: `${process.env.REACT_APP_BACKEND_URL}`,
  headers: {
    "X-Requested-With" : "XMLHttpRequest"
  }
}
);

// const isTokenExpired = (token) => {
//   if (!token) return true; // Token doesn't exist
//   const tokenData = JSON.parse(atob(token.split('.')[1]));
//   return Date.now() >= tokenData.exp * 1000; 
// };

// Interceptor for outgoing requests
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['authorization'] = `Bearer ${token}`;
    }

    // if (config.url.includes('percy-adapter.dev.fyelabs.com')) {
    //   config.baseURL = 'https://percy-adapter.dev.fyelabs.com/api/data'
    // }
    // else {
    //   config.baseURL = process.env.REACT_APP_BACKEND_URL;
    // }

    return config;

  },
  (error) => {
    return Promise.reject(error);
  }
);

// axiosInstance.interceptors.request.use(
//   (config) => {
//     const token = localStorage.getItem('token');
//     if (token && !isTokenExpired(token)) {
//       config.headers['authorization'] = `Bearer ${token}`;
//     } else {
//       // Token expired or doesn't exist, remove it
//       localStorage.removeItem('token');
//       window.location.href = '/login'; 
//     }
//     return config;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );


// Interceptor for incoming responses
axiosInstance.interceptors.response.use(
  (response) => {
    // Do something with the response data, e.g., logging
      // console.log('Response Data:', response.data);

    // You can also perform checks or transformations here
    return response;
  },
  async (error) => {
    localStorage.removeItem("token")
    console.log('error', error)
   
    if (error?.response?.data?.code === 403) {
      // Redirect the user to the login page or refresh the token
      // You can also handle other error codes as needed
      window.location.href = '/login';
      const getToken = localStorage.getItem('token');
      if (!getToken) {
        const res = await GetApi("/user/guestTokenGenerate")
        localStorage.setItem("token", res?.data?.token);
      }
    }
    return Promise.reject(error);
  }
);

// axiosInstance.interceptors.response.use(
//   (response) => {
//     return response;
//   },
//   async (error) => {
//     if (error?.response?.status === 403) {
//       localStorage.removeItem('token'); 
//       window.location.href = '/login'; 
//     }
//     return Promise.reject(error);
//   }
// );

export default axiosInstance;