//graph monitoring

import React, { useEffect, useRef, useState } from "react";
import { Box, CircularProgress, Grid, InputLabel, MenuItem, Select, Typography, Alert, AlertTitle } from "@mui/material";
import { LoadingButton } from '@mui/lab';
import "./EventMonitoring.css";
import { LocalizationProvider, DesktopDateTimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import CanvasJSReact from "@canvasjs/react-charts";
import { GetAdapterApi, GetApi } from "../../Api/Api";
import toast, { Toaster } from 'react-hot-toast';
import { format } from 'date-fns';
import { useTimeZone } from "../../Context/TimeZoneContext";
import { useDevice } from "../../Context/DeviceContext";
import { basePath } from '../../routes/Config';
import { Link } from "react-router-dom";
import { Chart, defaults } from 'chart.js/auto';
import { Line } from 'react-chartjs-2';


// sb_breath_rate, sb_heart_rate
// const labels = {
//   noise_value: "Sound (dB)",
//   temperature: "Temperature (°C)",
//   humidity: "Humidity (RH)",
//   light_value: "Light Value (lux)",
//   sb_breath_rate: "Bottom Radar Breathe Rate (BPM)",
//   sb_heart_rate: "Bottom Radar Heart Rate (BPM)",
//   sb_parameter: "Bottom Radar Parameter",
//   sb_distance: "Bottom Radar Distance",
//   eb_breath_rate: "Front Radar Breathe Rate (BPM)",
//   eb_heart_rate: "Front Radar Heart Rate (BPM)",
//   eb_parameter: "Front Radar Parameter",
//   eb_distance: "Front Radar Distance",
// };


const GraphsMonitoring = () => {
  // const [chartData, setChartData] = useState([]);
  // const [chartData2, setChartData2] = useState([]);
  const [xTimeValues, setXTimeValues] = useState([]);
  const [graphOneData, setGraphOneData] = useState([]);
  const [graphTwoData, setGraphTwoData] = useState([]);
  const [graphOneColor, setGraphOneColor] = useState([]);
  const [graphTwoColor, setGraphTwoColor] = useState([]);
  const [graphOneYAxis, setGraphOneYAxis] = useState([]);
  const [graphTwoYAxis, setGraphTwoYAxis] = useState([]);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [time, setTime] = useState('');
  const [selectedDataType, setSelectedDataType] = useState("");
  const [selectedDataType2, setSelectedDataType2] = useState("");
  const [selectedDataLabel, setSelectedDataLabel] = useState("");
  const [selectedDataLabel2, setSelectedDataLabel2] = useState("");
  const [sensorType, setSensorType] = useState("");
  const [sensorType2, setSensorType2] = useState("");
  const [loading, setLoading] = useState(false);
  const [startTimeValue, setStartTimeValue] = useState(null);
  const [endTimeValue, setEndTimeValue] = useState(null);
  // const [maxValue, setMaxValue] = useState(null);
  const CanvasJSChart = CanvasJSReact.CanvasJSChart;
  const chartRef = useRef({})
  const chartRef1 = useRef({})
  const { selectedTimeZone, timeZoneLabel } = useTimeZone();
  const { selectedDevice } = useDevice();

  const [listData, setListData] = useState([]);

  const [isSecondDropdown, setIsSecondDropdown] = useState(true);

  const handleDataTypeChange = (event) => {
    setSelectedDataType(event.target.value);
    // setChartData([]);
  };

  const handleDataType2Change = (event) => {
    setSelectedDataType2(event.target.value);
    if (event.target.value === "") {
      setIsSecondDropdown(false);
    }
    else {
      setIsSecondDropdown(true);
    }
    
    // setChartData([]);
  };

  const formatDateTimeWithTimezone = (dateTime) => {
    return format(dateTime, 'yyyy-MM-dd HH:mm:ss');
  };

  // Start Time and End Time -> auto picks for 24 hrs after start
  const handleStartTimeChange = (newStartTime) => {
    setStartTime(newStartTime);
    const newEndTime = new Date(newStartTime.getTime() + 24 * 60 * 60 * 1000); 
    setEndTime(newEndTime);
  };

  const handleEndTimeChange = (newEndTime) => {
    setEndTime(newEndTime);
  };

  // for first graph
  const fetchGraphOneData = async (startTime, endTime) => {
    const endpoint = `/${selectedDevice}/${selectedDataType.id}?start=${startTime}&end=${endTime}`;
    try {
      setLoading(true);
      const responseData = await GetAdapterApi(endpoint);
      console.log('graph one data: ', responseData.data);
      if (responseData.data) {
        setXTimeValues(responseData.data.xs);
        setGraphOneData(responseData.data.ys);
        setGraphOneColor(responseData.data.cs);

        // setStartTimeValue(responseData.data.startDate);
        // setEndTimeValue(responseData.data.endDate);
        // setChartDataFunc(responseData.data.data);
        // setMaxValue(responseData.data.max);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const fetchGraphTwoData = async (startTime, endTime) => {
    const endpoint = `/${selectedDevice}/${selectedDataType2.id}?start=${startTime}&end=${endTime}`;
    try {
      setLoading(true);
      const responseData = await GetAdapterApi(endpoint);
      console.log('graph two data: ', responseData.data);
      if (responseData.data) {
        // setTimeAxis(responseData.data.xs);
        setGraphTwoData(responseData.data.ys);
        setGraphTwoColor(responseData.data.cs);
        
        // setStartTimeValue(responseData.data.startDate);
        // setEndTimeValue(responseData.data.endDate);
        // setChartDataFunc(responseData.data.data);
        // setMaxValue(responseData.data.max);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const fetchAdapterData = async () => {
    const endpoint = `/${selectedDevice}/list`;
    try {
      const response = await GetAdapterApi(endpoint);
      console.log('list data (compare date page): ', response.data.options);
      setListData(response.data.options);
    } catch (error) {
      console.error('Error fetching list data: ', error);
    }
  }

  useEffect(() => {
    fetchAdapterData();
  }, [selectedDevice])


  const handleSubmit = async () => {
    if (!startTime || !endTime) {
      toast.error("Please select start and end time.");
      return;
    }

    const diffInHours = Math.abs(endTime - startTime) / 36e5;
    if (diffInHours > 24) {
      toast.error("Date range selection above 24 hours is not allowed.");
      return;
    }
    setTime(diffInHours > 1 ? 'hour' : 'minute');

    // toISOString() converts it to RFC3339
    const startDateWithTime = (new Date(startTime)).toISOString();
    const endDateWithTime = (new Date(endTime)).toISOString();

    if (selectedDataType) {
      setLoading(true);
      setSensorType(selectedDataType.id);
      setSelectedDataLabel(selectedDataType.name || "Select Data Type");
      setGraphOneYAxis(selectedDataType.labels);
      fetchGraphOneData(startDateWithTime, endDateWithTime); // with endpoint to call new data API
    }

    if (selectedDataType2) {
      setLoading(true);
      setSensorType2(selectedDataType2.id);
      setSelectedDataLabel2(selectedDataType2.name);
      setGraphTwoYAxis(selectedDataType2.labels);
      fetchGraphTwoData(startDateWithTime, endDateWithTime);
    }

    // const selectedDataTypes = [];
    // selectedDataTypes.push(selectedDataType);
    // if (selectedDataType2) {
    //   selectedDataTypes.push(selectedDataType2);
    // }

    // const endpoint = `/ts_raw_message/getRawMessages?startDate=${startDateWithTime}&endDate=${endDateWithTime}&name=${selectedDataTypes[0]}&timeZone=${selectedTimeZone}&deviceId=${selectedDevice}`;
    // const endpoint1 = `/ts_raw_message/getRawMessages?startDate=${startDateWithTime}&endDate=${endDateWithTime}&name=${selectedDataTypes[1]}&timeZone=${selectedTimeZone}&deviceId=${selectedDevice}`;

    // if (selectedDataType) {
    //   setLoading(true);
    //   fetchData(endpoint, setChartData);
    //   if (selectedDataType2) {
    //     fetchData(endpoint1, setChartData2);
    //     setSensorType2(selectedDataType2)
    //     setSelectedDataLabel2(labels[selectedDataType2] || "Select Data Type");
    //   }
    //   setSensorType(selectedDataType);
    //   setSelectedDataLabel(labels[selectedDataType] || "Select Data Type");
    // }
  };

  const ranges = [
    { startValue: 0, endValue: 30, color: "blue" },
    { startValue: 30, endValue: 50, color: "skyblue" },
    { startValue: 50, endValue: 70, color: "green" },
    { startValue: 70, endValue: 90, color: "yellow" },
    { startValue: 90, endValue: 120, color: "red" },
    { startValue: 120, endValue: 130, color: "#7C4700" },
    { startValue: 130, endValue: 140, color: "#362312" }
  ];

  const getStripLines = (labels) => {
    return labels.map(label => ({
      value: label.val,
      label: label.text,
      lineDashType: "dash",
      color: "#91D9B9",
      labelFontColor: "#91D9B9",
      labelAlign: "near",
    }));
  };

  const commonOptions = {
    theme: "dark2",
    height: 480,
    animationEnabled: true,
    exportEnabled: true,
    // exportFileName: `${exportedFileName}`,
    zoomEnabled: true,
    backgroundColor: "#042835",
    title: {
      text: `${selectedDataLabel} Over Time`,
      fontColor: "#91D9B9",
      fontSize: 20,
    },
    axisX: {
      title: `Time ${time === "hour" ? "(h)" : "(m)"}`,
      // valueFormatString: "HH:mm",
      // titleFontColor: "#91D9B9",
      // interval: time === "minute" ? 5 : 1,
      // intervalType: time === "hour" ? "hour" : "minute",
      // viewportMinimum: new Date(startTimeValue),
      // viewportMaximum: new Date(endTimeValue),
    },
    axisY: {
      title: selectedDataLabel,
      titleFontColor: "#91D9B9",
      lineThickness: 2,
      gridColor: "rgb(255, 255, 255, 40%)",
      gridThickness: 0,
      stripLines: getStripLines(graphOneYAxis),
    },
    legend: {
      fontSize: 14,
    },
  };

  const commonOptions2 = {
    theme: "dark2",
    height: 480,
    animationEnabled: true,
    exportEnabled: true,
    // exportFileName: `${exportedFileName}`,
    zoomEnabled: true,
    backgroundColor: "#042835",
    title: {
      text: `${selectedDataLabel2} Over Time`,
      fontColor: "#91D9B9",
      fontSize: 20,
    },
    axisX: {
      title: `Time ${time === "hour" ? "(h)" : "(m)"}`,
      // valueFormatString: "HH:mm",
      // titleFontColor: "#91D9B9",
      // interval: time === "minute" ? 5 : 1,
      // intervalType: time === "hour" ? "hour" : "minute",
      // viewportMinimum: new Date(startTimeValue),
      // viewportMaximum: new Date(endTimeValue)
    },
    axisY: {
      title: selectedDataLabel2,
      titleFontColor: "#91D9B9",
      lineThickness: 2,
      gridColor: "rgb(255, 255, 255, 40%)",
      gridThickness: 0,
      stripLines: getStripLines(graphTwoYAxis),
    },
    legend: {
      fontSize: 14,
    },
  };


  // const formattedData = chartData.map((data) => {
  //   let color, status;
  //   if (sensorType === 'noise_value') {
  //     status = parseFloat(data[sensorType]);
  //     if (status <= 30) {
  //       color = "blue";
  //     } else if (status <= 50) {
  //       color = "skyblue";
  //     } else if (status <= 70) {
  //       color = "green";
  //     } else if (status <= 90) {
  //       color = "yellow";
  //     } else if (status <= 120) {
  //       color = "red";
  //     } else if (status <= 130) {
  //       color = "#7C4700";
  //     } else if (status <= 140) {
  //       color = "#362312";
  //     }
  //   }
  //   return {
  //     x: new Date(data.msg_timestamp),
  //     y: parseFloat(data[sensorType]),
  //     status: color,
  //   };
  // });

  // const formattedData2 = chartData2.map((data) => {
  //   let color, status;
  //   if (sensorType2 === 'noise_value') {
  //     status = parseFloat(data[sensorType2]);
  //     if (status <= 30) {
  //       color = "blue";
  //     } else if (status <= 50) {
  //       color = "skyblue";
  //     } else if (status <= 70) {
  //       color = "green";
  //     } else if (status <= 90) {
  //       color = "yellow";
  //     } else if (status <= 120) {
  //       color = "red";
  //     } else if (status <= 130) {
  //       color = "#7C4700";
  //     } else if (status <= 140) {
  //       color = "#362312";
  //     }
  //   }
  //   return {
  //     x: new Date(data.msg_timestamp),
  //     y: parseFloat(data[sensorType2]),
  //     status: color,
  //   };
  // });


  const noiseLegendColors = {
    "0-30 db": "blue",
    "31-50 db": "skyblue",
    "51-70 db": "green",
    "71-90 db": "yellow",
    "91-120 db": "red",
    "121-130 db": "#7C4700",
    "131-140 db": "#362312"
  }

  // TEMPORARILY COMMENTED OUT

  // const getDataPoints = (xData, yData) => {
  //   if (xData.length > 0 && yData.length > 0) {
  //     const dataPoints = xData.map((x, index) => ({
  //       x: new Date(x),
  //       y: yData[index],
  //     }));
  //     // console.log('data: ', dataPoints);
  //     return dataPoints;
  //   }
  // }

  // const options = {
  //   ...commonOptions,
  //   axisY: {
  //     ...commonOptions.axisY,
  //     includeZero: true,
  //   },
  //   data: [
  //     {
  //       type: "line",
  //       xValueFormatString: "MM-DD-YY HH:mm:ss",
  //       toolTipContent: `Time : <strong>{x} ${timeZoneLabel}</strong><br/>${selectedDataLabel}: <strong> {y} ${sensorType === "noise_value" ? '({status})' : ''} </strong>`,
  //       color: "#91D9B9",
  //       dataPoints: getDataPoints(xTimeValues, graphOneData), // new dataPoints from data API
  //       lineColor: '#91D9B9',
  //       markerColor: '{color}',
  //       showInLegend: true,
  //       markerSize: 4,
  //       legendMarkerType: "square",
  //     }
  //   ]
  // };

  // const options2 = {
  //   ...commonOptions2,
  //   axisY: {
  //     ...commonOptions2.axisY,
  //     includeZero: true,
  //   },
  //   data: [
  //     {
  //       type: "line",
  //       xValueFormatString: "MM-DD-YY HH:mm:ss",
  //       toolTipContent: `Time : <strong>{x} ${timeZoneLabel}</strong><br/>${selectedDataLabel}: <strong> {y} ${sensorType === "noise_value" ? '({status})' : ''} </strong>`,
  //       color: "#91D9B9",
  //       dataPoints: getDataPoints(xTimeValues, graphTwoData), // new dataPoints from data API
  //       lineColor: '#91D9B9',
  //       markerColor: '{color}',
  //       showInLegend: true,
  //       markerSize: 4,
  //       legendMarkerType: "square",
  //     }
  //   ]
  // };


  useEffect(() => {
    const today = new Date();
    const oneDayMilliseconds = 1000 * 60 * 60 * 24;
    const startTimestamp = today.setHours(0, 0, 0, 0);
    const endTimestamp = today.setHours(23, 59, 59, 999);
    const yesterdayStartTimestamp = startTimestamp - oneDayMilliseconds;
    const yesterdayStartDate = new Date(yesterdayStartTimestamp);
    const yesterdayEndTimestamp = startTimestamp - 1;
    const yesterdayEndDate = new Date(yesterdayEndTimestamp);
    const diffInHours = Math.abs(yesterdayEndDate - yesterdayStartDate) / 36e5;
    setTime(diffInHours > 1 ? 'hour' : 'minute');
    const startDateWithTime = formatDateTimeWithTimezone(yesterdayStartDate);
    const endDateWithTime = formatDateTimeWithTimezone(yesterdayEndDate);
    // const endpoint = `/ts_raw_message/getRawMessages?startDate=${startDateWithTime}&endDate=${endDateWithTime}&name=${selectedDataType}&timeZone=${selectedTimeZone}&deviceId=${selectedDevice}`;
    // fetchData(endpoint);
  }, []);


  // useEffect(() => {
  //   if (chartRef.current && chartRef.current.render) {
  //     chartRef.current.render();
  //     if (chartRef1.current && chartRef1.current.render) {
  //       chartRef1.current.render();
  //     }
  //   }
  // }, [chartData]);

  // global options for Chart.JS
  // defaults.maintainAspectRatio = false;
  // defaults.responsive = true;
  defaults.plugins.title.display = true;
  defaults.plugins.title.align = "center";
  defaults.plugins.title.font.size = 20;
  defaults.plugins.title.color="#91D9B9";

  console.log('is second dropdown: ', isSecondDropdown);

  const singleGraphData = {
    labels: xTimeValues.map((label) => (new Date(label)).toLocaleTimeString()),
    datasets: [
      {
        label: selectedDataLabel,
        data: graphOneData.map((data) => data),
        borderColor: graphOneColor.map((color) => color),
      },
    ]
  }

  const singleGraphOptions = {
    scales: { 
      y: {
        title: {
          display: true,
          text: selectedDataLabel
        }
      },
    },
    elements: {
      line: {
        tension: 0.5
      },
    },
    plugins: {
      title: {
        text: isSecondDropdown ? (selectedDataLabel + " vs. " + selectedDataLabel2) : (selectedDataLabel)
      }
    }
  }
  
  const doubleGraphData = {
    labels: xTimeValues.map((label) => (new Date(label)).toLocaleTimeString()),
    datasets: [
      {
        label: selectedDataLabel,
        data: graphOneData.map((data) => data),
        borderColor: graphOneColor.map((color) => color),
        // default yAxisID here is 'y' because it is the first dataset
      },
      {
        label: selectedDataLabel2,
        data: graphTwoData.map((data) => data),
        borderColor: 'rgb(75, 192, 192, 0.6)', // temporary until the color values are set to a different color than graph 1
        yAxisID: 'axis2'
      }
    ]
  }

  const doubleGraphOptions = {
    scales: { 
      y: {
        title: {
          display: true,
          text: selectedDataLabel
        }
      },
      axis2: {
        position: 'right',
        title: {
          display: true,
          text: selectedDataLabel2
        }
      }
    },
    elements: {
      line: {
        tension: 0.5
      },
    },
    plugins: {
      title: {
        text: isSecondDropdown ? (selectedDataLabel + " vs. " + selectedDataLabel2) : (selectedDataLabel)
      }
    }
  }

  return (
    <>
    <Toaster position="top-right" reverseOrder={false} />
      {/* prev Box height was 700px */}
      <Box mt={1}>
        
        <Box className="title-with-border text-primery text--left" pb={2}>
          <Grid container spacing={2} sx={{ alignItems: "center" }}>
            <Grid item xs={12} md={3}> 
              <Typography component="h4" className="title">
                View Data
              </Typography>
            </Grid>
            <Grid item xs={12} md={9}>
              <Grid container spacing={2} sx={{ alignItems: "end" }}>
                <Grid item xs={12} md={3}>
                  <InputLabel className="label-color">Select Monitoring 1</InputLabel>
                  <Box className="select-option">
                    <Select
                      value={selectedDataType}
                      name={selectedDataLabel}
                      onChange={handleDataTypeChange}
                      displayEmpty
                      inputProps={{ "aria-label": "Select Data Type" }}
                    >
                      {listData.map((item) => (
                        <MenuItem key={item.id} value={item}> 
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                </Grid>
                <Grid item xs={12} md={3}>
                  <InputLabel className="label-color">Select Monitoring 2</InputLabel>
                  <Box className="select-option">
                    <Select
                      value={selectedDataType2}
                      name={selectedDataLabel2}
                      onChange={handleDataType2Change}
                      displayEmpty
                      inputProps={{ "aria-label": "Select Data Type" }}
                    >
                      <MenuItem value=""> None </MenuItem>
                      {listData.map((item) => (
                        <MenuItem key={item.id} value={item}> 
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                </Grid>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Grid item xs={6} md={2}>
                    <InputLabel className="label-color">Start time</InputLabel>
                    <Box className="select-option">
                      <DesktopDateTimePicker
                        value={startTime}
                        onChange={handleStartTimeChange}
                        disableFuture
                        format="MM-dd-yy HH:mm"
                        ampm={false}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={6} md={2}>
                    <InputLabel className="label-color">End Time</InputLabel>
                    <Box className="select-option">
                      <DesktopDateTimePicker
                        value={endTime}
                        onChange={handleEndTimeChange}
                        disableFuture
                        format="MM-dd-yy HH:mm"
                        ampm={false}
                      />
                    </Box>
                  </Grid>
                </LocalizationProvider>
                <Grid item xs={6} md={2} className="btn-font-small">
                  <Box className="btn-wrapper">
                    <LoadingButton className="loading-btn" variant="contained" loading={loading} onClick={handleSubmit}>
                      Submit
                    </LoadingButton>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>

        <Box mt={4}>
          {/* {loading ? (
            <CircularProgress style={{ color: '#91D9B9' }} />
          ) : graphOneData.length === 0 && graphTwoData.length === 0 ? (
            <Typography variant="h5" color="#91D9B9" align="center">
              No data found
            </Typography>
          ) : (
            <Grid container spacing={2} sx={{ alignItems: "center" }}>
              <Grid item xs={12} md={6}>
                <CanvasJSChart
                  options={options}
                  onRef={ref => chartRef.current = ref}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CanvasJSChart
                  options={options2}
                  onRef={ref => chartRef1.current = ref}
                />
              </Grid>
            </Grid>
          )} */}

          {graphOneData.length === 0 && graphTwoData.length === 0 ? (
            <> 
              <Box
                justifyContent="center"
                alignItems="center"
                display="flex"
              > 
                <Alert 
                  severity='warning' 
                  variant='outlined' 
                  className="alert-message"
                  sx={{
                    '& .MuiAlert-icon': {
                      color: '#91D9B9'
                    }
                  }}> 
                  <AlertTitle className="alert-text"> No Data Found </AlertTitle>
                </Alert>
              </Box>
            </>
          ) : (
            <>
              <Line 
                data={isSecondDropdown ? (doubleGraphData) : (singleGraphData)}
                options={isSecondDropdown ? (doubleGraphOptions) : (singleGraphOptions)}
              />
            </>
          )}
            
        </Box>
      </Box>
    </>

  );
};

export default GraphsMonitoring;

