import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

const UserCard = ({ UsersDetails }) => {
  return (
    <Card>
      <CardContent>
        <Typography variant="h5" component="div">
          First Name: {UsersDetails.first_name}
        </Typography>
        <Typography variant="h5" component="div">
          Middle Name: {UsersDetails.middle_name}
        </Typography>
        <Typography variant="h5" component="div">
          Last Name: {UsersDetails.last_name}
        </Typography>
        <Typography variant="h5" color="textSecondary">
          Email: {UsersDetails.email_id}
        </Typography>
        <Typography variant="h5" color="textSecondary">
          Phone Number: {UsersDetails.phone_no}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default UserCard;
