import { Box, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import "./styles.css";
import { getPatientGoals } from "../../../../Api/AdapterApi";

// Function to generate a color based on the patient's name
const generateColor = (name) => {
  let hash = 0;
  for (let i = 0; i < name.length; i++) {
    hash = name.charCodeAt(i) + ((hash << 5) - hash);
  }
  const color = `hsl(${hash % 360}, 95%, 85%)`;
  return color;
};

const PatientsDetailsCard = (props) => {
  const {
    name,
    controller,
    age,
    gender,
    weight,
    height,
    phone_no,
    email_id,
  } = props;

  const [goals, setGoals] = useState(null);

  const fetchPatientGoals = useCallback(async () => {
    try {
      if (goals || !props?.id) return;
      const params = { patient_id: props?.id };
      const response = await getPatientGoals(params);
      if (response?.goals) {
        setGoals(response?.goals);
      }
    } catch (error) {}
  }, [props?.id, setGoals, goals]);

  useEffect(() => {
    fetchPatientGoals();
  }, [fetchPatientGoals]);

  // Extract initials
  const getInitials = (name) => {
    if (!name) return "";
    const names = name.split(" ");
    const initials = names.map((n) => n[0]).join("");
    return initials.substring(0, 2).toUpperCase();
  };

  const initials = getInitials(name);
  const backgroundColor = generateColor(name || "");

  return (
    <Box className="my-patients-card-container">
      <Box className="patents-details-wrapper">
        <Box className="top-view">
          <Box className="user-image-box" style={{ backgroundColor }}>
            <Typography className="user-initials">{initials}</Typography>
          </Box>
          <Box ml={2}>
            <Typography className="user-name" component="h5">
              {name}
            </Typography>
            <Typography className="address">{email_id}</Typography>
            <Typography className="address">Phone: {phone_no}</Typography>
          </Box>
        </Box>
        <Box className="user-info-box">
          <Box>
            <Typography className="title">Age</Typography>
            <Typography className="sub-title" component="h6">
              {age}y
            </Typography>
          </Box>
          <Box className="border-dotted"></Box>
          <Box>
            <Typography className="title">Gender</Typography>
            <Typography className="sub-title" component="h6">
              {gender}
            </Typography>
          </Box>
          <Box className="border-dotted"></Box>
          <Box>
            <Typography className="title">Weight</Typography>
            <Typography className="sub-title" component="h6">
              {weight}lbs
            </Typography>
          </Box>
          <Box className="border-dotted"></Box>
          <Box>
            <Typography className="title">Height</Typography>
            <Typography className="sub-title" component="h6">
              {height}in
            </Typography>
          </Box>
        </Box>
        <Box mt={3} className="goal-set-box">
          {controller ? (
            <Typography className="title">
              Assigned Device: {controller}
            </Typography>
          ) : (
            <Typography className="title" sx={{ fontStyle: "italic" }}>
              No Assigned Device
            </Typography>
          )}
        </Box>
        <Box className="card-footer">
          <Box>
            {goals ? (
              <>
                <Typography className="title">Current Goals</Typography>
                {goals.map((goal, index) => (
                  <Typography className="sub-title" key={index}>
                    {index + 1}. {goal?.name}{" "}
                  </Typography>
                ))}
              </>
            ) : (
              <Typography className="title" sx={{ fontStyle: "italic" }}>
                No Current Goals{" "}
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PatientsDetailsCard;
