export const EVENT_SIDE_PADDING = 20;
export const EVENT_HEIGHT = 300;
export const GOAL_SQUARE_SIZE = 16;
export const Y_PADDING_TOP = 30;
export const Y_PADDING_BOTTOM = 50;

export const EVENT_COLORS = {
  BG1: "#021A22",
  BG2: "#042835",
  Accent1: "#36535D",
  Accent2: "#598795",
  Accent3: "#91D9B9",
};
