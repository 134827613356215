import { Box, Typography, useMediaQuery } from "@mui/material";

const EventPill = ({ event }) => {
  const isSmall = useMediaQuery("(max-width: 700px)");

  return (
    <>
      <Box display="flex" gap={1} alignItems="center">
        {event?.icon && (
          <Box
            component="img"
            src={`${process.env.REACT_APP_BACKEND_URL}/eventicon/${event?.icon}`}
            alt="icon"
            height={isSmall ? "20px" : "40px"}
          />
        )}
        <Box justifyContent="flex-start">
          <Typography align="left" fontFamily="Acumin Pro" fontSize="20px">
            {event?.name}
          </Typography>
          <Typography
            align="left"
            style={{
              fontSize: "10px",
            }}
          >
            {event?.description}
          </Typography>
        </Box>
      </Box>
      <Box
        style={{ ...circleWrapperStyle, border: `4px solid ${event?.color}` }}
        borderColor={event?.color}
      >
        <Typography lineHeight={1} fontSize={"18px"}>
          {event?.value}
        </Typography>
        <Typography
          lineHeight={1}
          style={{
            fontSize: "10px",
          }}
        >
          {event?.units}
        </Typography>
      </Box>
    </>
  );
};

export default EventPill;

const circleWrapperStyle = {
  minHeight: "50px",
  minWidth: "50px",
  borderRadius: "50%",
  border: "1px solid",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};
