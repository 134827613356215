import { GetApi } from "./Api";

export const BASE_ADAPTER_URL = `https://percy-adapter.dev.fyelabs.com/api`;

// params = {patient_id: int}
export const getPatientGoals = async (params) => {
  const url = `${BASE_ADAPTER_URL}/patient/${params?.patient_id}/goals`;
  const result = await GetApi(url);

  return result?.data;
};

// params = {patient_id: int, start: string, end: string}
export const getPatientProgress = async (params) => {
  const url = `${BASE_ADAPTER_URL}/patient/${params?.patient_id}/progress?start=${params?.start}&end=${params?.end}`;
  const result = await GetApi(url);

  return result?.data;
};

// params = {patient_id: int, start: string, end: string}
export const getPatientTimeline = async (params) => {
  const url = `${BASE_ADAPTER_URL}/patient/${params?.patient_id}/timeline?start=${params?.start}&end=${params?.end}`;
  const result = await GetApi(url);

  return result?.data;
};

// params = {patient_id: int, day: string}
export const getPatientEvents = async (params) => {
  const url = `${BASE_ADAPTER_URL}/patient/${params?.patient_id}/events?day=${params?.day}`;
  const result = await GetApi(url);

  return result?.data;
};

// params = {patient_id: int, event_type_id: string, graph_id: string}
export const getPatientEventGraph = async (params) => {
  const url = `${BASE_ADAPTER_URL}/patient/${params?.patient_id}/graph/${params?.event_type_id}/${params?.graph_id}?start=${params?.start}&end=${params?.end}`;
  const result = await GetApi(url);

  return result?.data;
};

// params = {event_category: string}
export const getEventIcon = async (params) => {
  const url = `${BASE_ADAPTER_URL}/event/${params?.event_category}/icon`;
  const result = await GetApi(url);

  return result?.data;
};
