import React, { useEffect} from 'react'
import { Box, Button,  FormControl, Grid, InputLabel, MenuItem, Modal, Select,   Typography } from '@mui/material'
import './Profile.css'
import { Controller, useForm } from 'react-hook-form'
import Input from '../../Components/InputComponent/Input'
import ButtonComponent from '../../Components/ButtonComponent/ButtonComponent'
import { updateApi } from '../../Api/Api'
import toast from 'react-hot-toast'

const Profile = (props) => {
  const { handleClose, open } = props;

  const style = {
    position: 'absolute',
    top: '50%',
    left: 15,
    right: 15,
    transform: 'translateY(-50%)',
    maxWidth: 1000,
    color: '#ffffff',
    boxShadow: 24,
    margin: '1rem auto',
    maxHeight: "100%"
  };

  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm()

  const userDetails = JSON.parse(localStorage.getItem('user'));


  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        setValue('title', userDetails.title);
        setValue('first_name', userDetails.first_name);
        setValue('middle_name', userDetails.middle_name);
        setValue('last_name', userDetails.last_name);
        setValue('phone_no', userDetails.phone_no);
        setValue('email_id', userDetails.email_id);
      } catch (error) {
        console.error('Error fetching user details:', error);
      }
    };
    fetchUserDetails();
  }, [userDetails, setValue]);

  // const spaceValidation = (value) => {
  //   const first_name = watch("first_name"); 
  //   return value.trim().length === 0 || "First Name field is required";
  // };

  const NameValidation = (value) => {
    return !value.trim() || value.trim().length === 0 ? 'First Name field is required' : true;
  };
  
  const onSubmit = async (formData, event) => {
    event.preventDefault();
    const firstName = formData.first_name.trim();
    const lastName = formData.last_name.trim();
    const endpoint = `/user/${userDetails.id}`

    const updatedData = {
      ...formData,
      id: userDetails.id,
      first_name: firstName,
      last_name: lastName
    };

    try {
      const responseData = await updateApi(endpoint, { ...updatedData, id: userDetails.id });
      console.log('API response:', responseData.data);
      if (responseData.data) {
        console.log('Profile Updated');
        const updatedUserDetails = { ...userDetails, ...updatedData };
        localStorage.setItem('user', JSON.stringify(updatedUserDetails));
        reset();
        handleClose()
        toast.success("Profile Updated");
      } else {
        console.log('User is not authenticated');
        toast.error("something went wrong");
      }
    } catch (error) {
      console.log('Error:', error);
    }
  };

  return (
    <Box >
      <Modal
        open={open}
        onClose={handleClose}
        className="profile-modal"
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} >
          <Box className="modal-shadow">
            <Box className="modal-header" >
              <Typography id="modal-modal-title" className='text-primery' variant="h4" component="h4">
                Profile Update
              </Typography>
              <Button onClick={handleClose}>
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                  <path opacity="0.2" d="M32.0002 3.138L28.8632 0L16.4242 12.439L3.98517 0L0.847168 3.138L13.2862 15.577L0.847168 28.016L3.98517 31.154L16.4242 18.714L28.8632 31.153L32.0012 28.015L19.5612 15.577L32.0002 3.138Z" fill="white" />
                </svg>
              </Button>
            </Box>
            <Box className="modal-body" id="modal-modal-description" component="form" onSubmit={handleSubmit(onSubmit)}>
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6}>
                    <Box className="form-group select-form-group">
                      <FormControl fullWidth>
                        <InputLabel shrink className='input-label'>Title</InputLabel>
                        <Controller
                          name="title"
                          control={control}
                          // defaultValue={userDetails.title ?? ''}
                          render={({ field }) => (
                            <Select
                              {...field}
                              displayEmpty
                              onChange={(e) => {
                                setValue('title', e.target.value);
                                field.onChange(e);
                              }}
                            >
                              <MenuItem value=""><span>Mr / Ms</span></MenuItem>
                              <MenuItem value="Mr">Mr.</MenuItem>
                              <MenuItem value="Ms">Miss</MenuItem>
                            </Select>
                          )}
                          rules={{ required: 'Title is required' }}
                        />
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Box className="form-group">
                      <Input
                        label={"First Name"}
                        placeholder="Lindsey"
                        type="text"
                        name="first_name"
                        requiredFeild="requiredFeild"
                        register={register}
                        validationRules={{
                          required: 'First Name is required',
                          pattern: {
                            value: /^[a-zA-Z\s]+$/,
                            message: 'First Name should contain only letters and spaces'
                          },
                          validate: NameValidation
                        }} />
                      {errors.first_name && <p className="error-message">{errors.first_name.message}</p>}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Box className="form-group">
                      <Input
                        label="Middle Name"
                        placeholder="Daniel"
                        type="text"
                        name="middle_name"
                        register={register}
                      // validationRules={{
                      //   required: 'Middle Name is required',
                      // }} 
                      />
                      {/* {errors.middle_name && <p className="error-message">{errors.middle_name.message}</p>} */}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Box className="form-group">
                      <Input
                        label="Last Name"
                        placeholder="Thomas"
                        type="text"
                        name="last_name"
                        requiredFeild="requiredFeild"
                        register={register}
                        validationRules={{
                          required: 'Last Name is required',
                          validate: NameValidation,
                          pattern: {
                            value: /^[a-zA-Z\s]+$/,
                            message: 'First Name should contain only letters and spaces'
                          },
                        }} />
                      {errors.last_name && <p className="error-message">{errors.last_name.message}</p>}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Box className="form-group">
                      <Input
                        label="Phone Number"
                        placeholder="963214587"
                        type="text"
                        name="phone_no"
                        requiredFeild="requiredFeild"
                        register={register}
                        validationRules={{
                          required: 'Phone Number is required',
                          pattern: {
                            value: /^\d{10}$/,
                            message: 'Please enter a valid 10-digit Phone Number'
                          }
                        }}
                      />
                      {errors.phone_no && <p className="error-message">{errors.phone_no.message}</p>}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Box className="form-group">
                      <Input
                        label="Email"
                        placeholder="apollo@gmail.com"
                        type="email"
                        name="email_id"
                        disabled
                        register={register}
                        validationRules={{
                          required: 'Email is required',
                          pattern: {
                            value: /^\S+@\S+$/i,
                            message: 'Please enter a valid email address'
                          }
                        }}
                        
                      />
                      {errors.email_id && <p className="error-message">{errors.email_id.message}</p>}
                    </Box>
                  </Grid>
                  {/* <Grid item xs={12} sm={6} md={3}>
                    <Box className="form-group">
                      <Box className="select-opation--wrapper">
                        <FormControl variant="standard">
                          <InputLabel shrink className='input-label'>Role Id</InputLabel>
                          <Controller
                            name="role_id"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                              <Autocomplete
                                {...field}
                                disablePortal
                                id="combo-box-demo"
                                options={roleData}
                                getOptionLabel={(role) => role.name || ''}
                                sx={{ width: '100%' }}
                                renderInput={(params) => <TextField placeholder={roleDetails? roleDetails.name :""} {...params} />}
                                onChange={(_, value) => setValue('role_id', value ? value.id : '')}
                                value={roleData.find((role) => role.id === field.value) || null}
                              />
                            )}
                          />
                        </FormControl>
                      </Box>
                    </Box>
                  </Grid> */}

                  <Grid item xs={12} sm={12} md={12}>
                    <Box className="form-group">
                      <Box className="btn-center">
                        <ButtonComponent type="submit" buttonText="Profile Update" buttonVariant="contained" />
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>


    </Box>
  )
}

export default Profile