import React, { useEffect, useState } from "react";
import ButtonComponent from "../../Components/ButtonComponent/ButtonComponent";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Tab,
  Tabs,
  Grid,
  Button,
  Box,
} from "@mui/material";
import { Add as AddIcon } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { useParams } from "react-router-dom";
import { GetApiParam } from "../../Api/Api";
import SetGoalComponent from "../SetGoalComponent/SetGoalComponent";

import "../../Components/SelectComponent/SelectComponent.css"

import {
  PatientDetailsCard,
  AddTreatments,
  ProgressCard,
  TimelineCard,
  EventsCard,
} from "./components";
import { PATIENT_ROUTE } from "../../Api/Routes";

const PatientsDetails = () => {
  const [open, setOpen] = useState(false);
  const [editValue, setEditValue] = useState(false);
  const [patientDetails, setPatientDetails] = useState([]);
  const { id } = useParams();

  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  
  useEffect(() => {
    const fetchPatientDetails = async () => {
      try {
        const response = await GetApiParam(`${PATIENT_ROUTE}/${id}`);
        setPatientDetails(response.data);
      } catch (error) {
        console.error("Error fetching patient details:", error);
      }
    };
    fetchPatientDetails();
  }, [id]);

  const handleOpen = () => {
    setOpen(true);
    setEditValue(false);
  };

  const handleEditOpen = () => {
    setOpen(true);
    setEditValue(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleTabChange = (e, tabIndex) => {
    setCurrentTabIndex(tabIndex);
  };

  return (
    <Box>
      <Box className="my-patients-topbar" mt={3}>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
          <Tabs
            className="title"
            component="h4"
            value={currentTabIndex}
            onChange={handleTabChange}
            TabIndicatorProps={{
              sx: { backgroundColor: "#91D9B9" },
            }}
            sx={{
              ".MuiButtonBase-root": {
                color: "#91D9B9 !important",
              },
            }}
          >
            <Tab label="Overview" />
            <Tab label="Goal Setup" />
            <Tab label="Calibration" />
          </Tabs>
          <Button
            variant="contained"
            color="info"
            className="btn-primary"
            sx={{ fontWeight: 'bold' }}
          >
            Edit Patient
          </Button>
        </Box>
      </Box>
      {currentTabIndex === 0 && (
        <>
          {/* Patient Card */}
          <Box mt={3}>
            <Grid container spacing={3}>
              <Grid item sm={12} xs={12} md={5} lg={4}>
                <PatientDetailsCard
                  {...patientDetails}
                  controller={patientDetails?.device_name}
                />
              </Grid>
              <Grid item sm={12} xs={12} md={7} lg={8}>
                <ProgressCard patientId={id} />
              </Grid>
            </Grid>
          </Box>
          {/* Events Section */}
          <EventsCard patientId={id} />
          {/* Timeline */}
          <TimelineCard patientId={id} />
          {/* Treatment History */}
          <Box className="my-patients-topbar" mt={3}>
            <Box>
              <Typography className="title" component="h4">
                Treatment History
              </Typography>
            </Box>
            <Box className="right btn-font-small">
              <ButtonComponent
                buttonText="Add Treatment"
                buttonVariant="contained"
                startIcon={<AddIcon />}
                buttonStyle={{ borderRadius: "30px" }}
                onClick={handleOpen}
              />
            </Box>
          </Box>
          <TableContainer component={Paper}>
            <Table style={{ borderCollapse: "collapse", minWidth: 650 }}>
              <TableHead>
                <TableRow>
                  <StyledTableCell> Prescription </StyledTableCell>
                  <StyledTableCell> Start Date </StyledTableCell>
                  <StyledTableCell> End Date </StyledTableCell>
                  <StyledTableCell> Notes </StyledTableCell>
                  <StyledTableCell> {""} </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {displayData.map((treatment, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell component="th" scope="row">
                      {treatment.prescription}
                    </StyledTableCell>
                    <StyledTableCell>{treatment.start_date}</StyledTableCell>
                    <StyledTableCell>{treatment.end_date}</StyledTableCell>
                    <StyledTableCell> {treatment.notes} </StyledTableCell>
                    <StyledTableCell sx={{ textAlign: "right" }}>
                      <Button
                        variant="contained"
                        color="info"
                        className="btn-primary"
                        sx={{ width: "45%" }}
                        onClick={handleEditOpen}
                      >
                        Edit
                      </Button>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <AddTreatments
            open={open}
            handleClose={handleClose}
            editValue={editValue}
          />
        </>
      )}

      {/* Goals Setup Tab */}
      {currentTabIndex === 1 && <SetGoalComponent />}
    </Box>
  );
};

export default PatientsDetails;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#042835",
    color: "rgb(255,255, 255, 70%)",
    borderBottom: "1px solid #021A22",
    fontFamily: "Acumin Pro",
    fontWeight: 700,
    fontSize: 16,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    backgroundColor: "#03212b",
    color: "rgb(255,255, 255, 40%)",
    borderBottom: "1px solid #021A22",
    fontFamily: "Acumin Pro",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const displayData = [
  {
    prescription: "Clonindine 0.50 mg",
    start_date: "2024-08-09",
    end_date: "2024-08-11",
    notes: "",
  },
  {
    prescription: "Clonindine 0.50 mg",
    start_date: "2024-08-11",
    end_date: "2024-08-14",
    notes: "",
  },
  {
    prescription: "Melatonin 5 mg",
    start_date: "2024-08-14",
    end_date: "In Effect",
    notes: "",
  },
];