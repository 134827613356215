import React, { useEffect, useState } from "react";
import "../MyPatients/MyPatients.css";
import { Box, CircularProgress,  Stack, Typography } from "@mui/material";
import ButtonComponent from "../../Components/ButtonComponent/ButtonComponent";
import AddIcon from "@mui/icons-material/Add";
import AddPhysician from "./Add physician/AddPhysician";
import { GetApi } from "../../Api/Api";
import PhysicianTable from "./components/PhysicianTable";
import ListOutlinedIcon from "@mui/icons-material/ListOutlined";
import { Toaster } from "react-hot-toast";

const Physician = () => {
  const [open, setOpen] = useState(false);
  const [physicianData, setPhysicianData] = useState([]);
  const [loading, setLoading] = useState(true);
  // const [error, setError] = useState(null);

  const hospitalData = JSON.parse(localStorage.getItem("hospitalData"));
  console.log("hospitalData", hospitalData);

  const fetchPhysicianData = async () => {
    const endpoint = "/physicians/";
    try {
      const response = await GetApi(endpoint);
      console.log(response, "response------------------");
      setPhysicianData(response.data.data);
      setLoading(false);
    } catch (error) {
      // setError(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPhysicianData();
  }, []);

  const AddUpdatePhysician = () => {
    fetchPhysicianData();
  };

  if (loading) {
    return <CircularProgress style={{ margin: "20px" }} />;
  }
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box>
      <Toaster position="top-right" reverseOrder={false} />
      <Box className="my-patients-topbar" mt={3}>
        <Box>
          <Typography className="title" component="h4">
            Physicians
          </Typography>
        </Box>
        <Box className="right btn-font-small">
          <Stack direction="row" gap={2}>
            <ButtonComponent
              buttonText="Add physician"
              buttonVariant="contained"
              buttonStyle={{ borderRadius: "100px" }}
              startIcon={<AddIcon />}
              onClick={handleOpen}
            />
            <ButtonComponent
              buttonText={<ListOutlinedIcon />}
              buttonVariant="outline"
              buttonStyle={{ borderRadius: "10px" }}
              buttonClass="btn-small only-icon-btn"
            />
          </Stack>
        </Box>
      </Box>
      <Box sx={{ mt: "30px" }}>
        <PhysicianTable
          physicianData={physicianData}
          hospitalData={hospitalData}
          AddUpdatePhysician={AddUpdatePhysician}
        />
      </Box>
      <AddPhysician
        open={open}
        handleClose={handleClose}
        AddUpdatePhysician={AddUpdatePhysician}
        hospitalData={hospitalData}
      />
    </Box>
  );
};

export default Physician;
