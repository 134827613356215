// AppRouter.js
import React, { Suspense, useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Dashboard from "../Screens/Dashboard/Dashboard";
import MyPatients from "../Screens/MyPatients/MyPatients";
import Login from "../Screens/Login/Login";
import Layout from "../Layouts/Layout/Layout";
import PrivateRoutes from "./PrivateRoutes";
import Hospitals from "../Screens/Hospital/Hospital";
import Physician from "../Screens/Physician/Physician";
import User from "../Screens/User/User";
import SetGoalComponent from "../Screens/SetGoalComponent/SetGoalComponent";
import HospitalDetail from "../Screens/Hospital/HospitalsDetails";
import PhysiciansDetails from "../Screens/Physician/PhysicianDetails";
import UsersDetails from "../Screens/User/UserDetails";
import Controllers from "../Screens/Controller/Controller";
import ControllerDetail from "../Screens/Controller/ControllersDetails";
import ForgotPassword from "../Screens/Login/ForgotPassword";
import MyPatientDetails from "../Screens/MyPatients/MyPatientDetails";
import { AuthProvider } from "../Context/AuthContext"; // to access user role
import ResetPassword from "../Screens/Login/ResetPassword";
import CheckEmail from "../Screens/Login/CheckEmail";
import LoginRoutes from "./LoginRoutes";
import VideoPlayer from "../Screens/VideoMonitoring/VideoPlayer";
// import GraphLayout from '../Layouts/Layout/GraphLayout';
// import OptimizeCode from '../Screens/GraphsMonitoring/OptimizeCode';
import { basePath } from "./Config";
import UserDetails from "../Components/UserDetails";
import Prescription from "../Screens/Prescription/Prescription";
import PrescriptionDetails from "../Screens/Prescription/PrescriptionDetails";
import CssTutorial from "../dummyCss/CssTutorial";
import GraphsMonitoring from "../Screens/GraphsMonitoring/GraphsMonitoring";
import toast from "react-hot-toast"; // to dismiss persisting error message across pages
const EventComponent = React.lazy(() =>
  import("../Screens/EventMonitoring/EventManagement")
);
const SensorComponent = React.lazy(() =>
  import("../Screens/SensorsMonitoring/SensorMonitoring")
);
const VideoComponent = React.lazy(() =>
  import("../Screens/VideoMonitoring/VideoMonitoring")
);

const adminRoutes = [
  {
    path: basePath,
    element: <Layout />,
    children: [
      {
        path: "my-hospitals",
        element: <PrivateRoutes component={Hospitals} roles={["admin"]} />,
      },
      {
        path: "my-hospitals/:id",
        element: <PrivateRoutes component={HospitalDetail} roles={["admin"]} />,
      },
      {
        path: "my-physician",
        element: <PrivateRoutes component={Physician} roles={["admin"]} />,
      },
      {
        path: "my-physician/:id",
        element: (
          <PrivateRoutes component={PhysiciansDetails} roles={["admin"]} />
        ),
      },
      {
        path: "my-users",
        element: <PrivateRoutes component={User} roles={["admin"]} />,
      }, // Super Admin
      {
        path: "my-users/:id",
        element: <PrivateRoutes component={UsersDetails} roles={["admin"]} />,
      }, // Super Admin
    ],
  },
];

const commonRoutes = [
  {
    path: basePath,
    element: <Layout />,
    children: [
      {
        index: true,
        element: (
          <PrivateRoutes component={Dashboard} roles={["admin", "physician"]} />
        ),
      },
      {
        path: "my-patients",
        element: (
          <PrivateRoutes
            component={MyPatients}
            roles={["admin", "physician"]}
          />
        ),
      },
      {
        path: "goals-setup",
        element: (
          <PrivateRoutes
            component={SetGoalComponent}
            roles={["admin", "physician"]}
          />
        ),
      },
      {
        path: "my-patients/:id",
        element: (
          <PrivateRoutes
            component={MyPatientDetails}
            roles={["admin", "physician"]}
          />
        ),
      },
      {
        path: "my-controllers",
        element: (
          <PrivateRoutes
            component={Controllers}
            roles={["admin", "physician"]}
          />
        ),
      },
      {
        path: "my-controllers/:id",
        element: (
          <PrivateRoutes
            component={ControllerDetail}
            roles={["admin", "physician"]}
          />
        ),
      },
      {
        path: "sensors-monitoring",
        element: (
          <Suspense>
            <PrivateRoutes
              component={SensorComponent}
              roles={["admin", "physician"]}
            />{" "}
          </Suspense>
        ),
      },
      {
        path: "event-monitoring",
        element: (
          <Suspense>
            <PrivateRoutes
              component={EventComponent}
              roles={["admin", "physician"]}
            />{" "}
          </Suspense>
        ),
      },
      {
        path: "video-monitoring",
        element: (
          <Suspense>
            <PrivateRoutes
              component={VideoComponent}
              roles={["admin", "physician"]}
            />{" "}
          </Suspense>
        ),
      },
      {
        path: "video-monitoring/:id",
        element: (
          <PrivateRoutes
            component={VideoPlayer}
            roles={["admin", "physician"]}
          />
        ),
      },
      {
        path: "dummy-form",
        element: (
          <PrivateRoutes
            component={UserDetails}
            roles={["admin", "physician"]}
          />
        ),
      },
      {
        path: "prescription",
        element: (
          <PrivateRoutes
            component={Prescription}
            roles={["admin", "physician"]}
          />
        ),
      },
      {
        path: "prescription-details",
        element: (
          <PrivateRoutes
            component={PrescriptionDetails}
            roles={["admin", "physician"]}
          />
        ),
      },
      {
        path: "css",
        element: (
          <PrivateRoutes
            component={CssTutorial}
            roles={["admin", "physician"]}
          />
        ),
      },
      {
        path: "graph-monitoring",
        element: (
          <PrivateRoutes
            component={GraphsMonitoring}
            roles={["admin", "physician"]}
          />
        ),
      },
      // { path: "my-profile", element: <PrivateRoutes component={Profile} roles={['admin', 'physician']} /> },
      // { path: "change-password", element: <PrivateRoutes component={ChangePassword} roles={['admin', 'physician']} /> },
    ],
  },
  // {
  //   path: basePath, element: <GraphLayout />, children: [
  //     { path: "graph-monitoring", element: <PrivateRoutes component={GraphsMonitoring} roles={['admin', 'physician']} />},
  //     // { path: "optimize", element: <PrivateRoutes component={OptimizeCode} roles={['admin']} />},
  //   ]
  // },
];

const AppRouter = () => {
  // const { user } = useAuth();
  // const user = JSON.parse(localStorage.getItem('user'));
  // console.log('user: ', user);
  // admin role ID = 1
  // phys role ID = 0

  // not restricting actual access, just hiding tabs from physician for now
  // if needed to restrict access, add check to adminRoutes below using role_id

  const location = useLocation();

  useEffect(() => {
    toast.remove();
  }, [location]);

  return (
    <AuthProvider>
      <Routes>
        {commonRoutes.map((route, index) => (
          <Route key={index} path={route.path} element={route.element}>
            {Array.isArray(route.children) &&
              route.children.map((childRoute, childIndex) => (
                <Route key={childIndex} {...childRoute} />
              ))}
          </Route>
        ))}

        {adminRoutes.map((route, index) => (
          <Route key={index} path={route.path} element={route.element}>
            {Array.isArray(route.children) &&
              route.children.map((childRoute, childIndex) => (
                <Route key={childIndex} {...childRoute} />
              ))}
          </Route>
        ))}

        <Route
          path={`${basePath}login`}
          element={<LoginRoutes component={Login} />}
        />
        <Route
          path={`${basePath}forget-password`}
          element={<LoginRoutes component={ForgotPassword} />}
        />
        <Route
          path={`${basePath}reset-password/:token`}
          element={<LoginRoutes component={ResetPassword} />}
        />
        <Route
          path={`${basePath}email-link`}
          element={<LoginRoutes component={CheckEmail} />}
        />
      </Routes>
    </AuthProvider>
  );
};

export default AppRouter;
