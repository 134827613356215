import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

const ControllerCard = ({ controllerDetail }) => {
  return (
    <Card>
      <CardContent>
        <Typography variant="h5" component="div">
          Controller Name: {controllerDetail.controller_name}
        </Typography>
        <Typography variant="h5" color="textSecondary">
          Serial Number: {controllerDetail.serial_no}
        </Typography>
        <Typography variant="h5" color="textSecondary">
          Mac Address: {controllerDetail.mac_address}
        </Typography>
        <Typography variant="h5" color="textSecondary">
          Activation Date: {controllerDetail.activation_date}
        </Typography>
        <Typography variant="h5" color="textSecondary">
          DeActivation Date: {controllerDetail.deactivation_date}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default ControllerCard;
