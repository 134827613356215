import { Box, Typography } from "@mui/material";

const TimelinePopper = ({ events }) => {
  //filter events with unique event?.text
  events = events.filter(
    (v, i, a) => a.findIndex((t) => t.text === v.text) === i
  );

  return (
    <Box sx={wrapperStyle}>
      <Typography
        component="div"
        fontSize="20px"
        className="text-primery"
        align="center"
        sx={{ textDecoration: "underline" }}
      >
        Events
      </Typography>

      <Box sx={eventWrapperStyle}>
        {events.map((e, index) => (
          <EventItem key={index} event={e} />
        ))}
      </Box>
    </Box>
  );
};

const EventItem = ({ event }) => {
  return (
    <Box sx={eventItemStyle}>
      <Box sx={{ ...eventColor, bgcolor: event?.color }} />
      {event?.text}
    </Box>
  );
};

export default TimelinePopper;

const wrapperStyle = {
  border: "1px solid #91D9B9",
  p: "20px",
  width: "400px",
  bgcolor: "#042835",
  color: "#fff",
  borderRadius: "10px",
};

const eventWrapperStyle = {
  mt: 1.5,
  display: "flex",
  flexWrap: "wrap",
};

const eventItemStyle = {
  flex: "1 0 48%",
  height: "40px",
  alignItems: "center",
  display: "flex",
  pt: 1,
  gap: "5px",
};

const eventColor = {
  height: "30px",
  width: "30px",
};
