import axios from 'axios';
import axiosInstance from './AuthServices';

/**
 * Perform a POST request to update data via API.
 * @param {string} url - The URL of the API.
 * @param {Object<any>} body - The body of the request.
 * @returns {Promise<AxiosResponse>} - A Promise that resolves with the API response.
 */
export const postApi = async (url, body) => {
  return await axiosInstance.post(`${url}`, body)
    .then(res => {
      return res;
    })
    .catch((error) => {
      return error;
    })
}

/**
 * Perform a PUT request to update data via API.
 * @param {string} url - The URL of the API.
 * @param {Object<any>} body - The body of the request.
 * @returns {Promise<AxiosResponse>} - A Promise that resolves with the API response.
 */
export const updateApi = async (url, body) => {
  return await axiosInstance.put(`${url}`, body)
    .then(res => {
      return res;
    })
    .catch((error) => {
      return error;
    })
}

/**
 * Perform a GET request to an API without query parameters.
 * @param {string} url - The URL of the API.
 * @returns {Promise<AxiosResponse>} - A Promise that resolves with the API response.
 */
export const GetApi = async (url) => {
  return await axiosInstance.get(`${url}`);
}

/**
 * Perform a GET request to an API with query parameters.
 * @param {string} url - The URL of the API.
 * @param {Object<any>} body - The query parameters of the request.
 * @returns {Promise<AxiosResponse>} - A Promise that resolves with the API response.
 */
export const GetApiParam = async (url, body) => {
  return await axiosInstance.get(`${url}`, {
    params: body,
  });
}

/**
 * Perform a GET request to an API without query parameters.
 * @param {string} url - The URL of the API.
 * @returns {Promise<AxiosResponse>} - A Promise that resolves with the API response.
 */
export const GetAdapterApi = async (url) => {
  const adaptedURL = `https://percy-adapter.dev.fyelabs.com/api/data${url}`
  return await axios.get(adaptedURL);
}

/**
 * Fetch Google user information using the provided access token.
 * @param {string} url - The API endpoint for fetching Google user information.
 * @param {string} access_token - The Google access token for authentication.
 * @param {Object} headers - Additional headers to include in the request.
 * @returns {Promise<AxiosResponse|Error>} - A Promise that resolves with the API response or rejects with an error.
 */
export const googleUserInfo = async (url, access_token, headers) => {
  return await axios.get(`${url}${access_token}`, { headers })
    .then(res => {
      return res;
    })
    .catch((error) => {
      return error;
    })
}

/**
 * API method to perform a delete request.
 * @description Delete Api
 * @param {string} url - The API endpoint for the delete request.
 * @returns {Promise<AxiosResponse>} - A Promise that resolves with the API response.
 */
export const deleteApi = async (url) => {
  return await axiosInstance.delete(`${url}`)
}

/**
 * API method to delete an asset.
 * @param {string} url - The API endpoint for deleting the asset.
 * @returns {Promise} - A Promise that resolves with the API response.
 */
export const assetDeleteApi = async (url) => {
  return await axiosInstance.delete(`${url}`)
}

/**
 * API method to update parcel data for an asset.
 * @param {string} url - The API endpoint for updating parcel data.
 * @param {Object} body - The data to be updated for the parcel.
 * @returns {Promise} - A Promise that resolves with the API response.
 */
export const assetUpdateParcelApi = async (url, body) => {
  return await axiosInstance.put(`${url}`, body)
}
