import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

const HospitalCard = ({ hospitalDetail }) => {
  return (
    <Card>
      <CardContent>
        <Typography variant="h5" component="div">
          Hospital Name: {hospitalDetail.hospital_name}
        </Typography>
        <Typography variant="h5" color="textSecondary">
          Email: {hospitalDetail.email_id}
        </Typography>
        <Typography variant="h5" color="textSecondary">
          Phone Number: {hospitalDetail.phone_no}
        </Typography>
        <Typography variant="h5" color="textSecondary">
          Address: {hospitalDetail.address}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default HospitalCard;
