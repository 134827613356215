import { Box } from '@mui/material';
import React from 'react';
import { useLocation, useParams } from 'react-router-dom';

const VideoPlayer = () => {
  // const { videoId } = useParams(); // Assuming the videoId is passed as a URL parameter
  // const location = useLocation();
  // const videoUrl = location.state.videoUrl;
  // const videoUrl = 'https://thermal-camera-percy.s3.amazonaws.com/1/video/output_1_1712910798927.mp4'
  // const videoUrl = `https://thermal-camera-percy.s3.amazonaws.com/${videoId}/video/${videoName}`; 

  const location = useLocation();
    const videoUrl = decodeURIComponent(new URLSearchParams(location.search).get('videoUrl'));

  return (
    <Box>
      <Box style={{ maxWidth:"100%", margin: '150px auto auto auto' }}>
        <video controls style={{ height: 'auto', maxHeight: "calc(100vh - 170px)" }}>
          <source src={videoUrl} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </Box>
    </Box>
  );
};

export default VideoPlayer;


