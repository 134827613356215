import React, { useEffect, useState } from 'react'
import "../MyPatients/MyPatients.css"
import "../../Assets/Css/Responsive.css"
import { Box, CircularProgress, Stack, Typography } from '@mui/material'
import ButtonComponent from '../../Components/ButtonComponent/ButtonComponent'
import AddIcon from '@mui/icons-material/Add';
import AddControllers from './Add controllers/AddControllers';
import { GetApi } from '../../Api/Api';
import ControllerTable from './components/ControllerTable';
import ListOutlinedIcon from '@mui/icons-material/ListOutlined';
import { Toaster } from 'react-hot-toast'

const Controllers = () => {
    const [open, setOpen] = useState(false);
    const [controllerData, setControllerData] = useState([]);
    const [loading, setLoading] = useState(true);
    // const [error, setError] = useState(null);

    const fetchControllerData = async () => {
        const endpoint = '/controllers/';
        try {
            const response = await GetApi(endpoint);
            console.log(response, 'response------------------')
            setControllerData(response.data.data);
            setLoading(false);
        } catch (error) {
            // setError(error);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchControllerData();
    }, []);


    const AddUpdateController = () => {
        fetchControllerData();
      };

    console.log('controllerData-------------', controllerData)


    if (loading) {
        return <CircularProgress style={{ margin: '20px' }} />;
    }

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    return (
        <Box>
            <Toaster position="top-right" reverseOrder={false} />
            <Box className="my-patients-topbar" mt={3}>
                <Box>
                    {/* changed from Hopsital to Controllers - not sure if that was a typo */}
                    <Typography className='title' component="h4">Controllers</Typography>
                </Box>
                {/* <Box className="right btn-font-small">
                    <Stack direction="row" gap={2}>
                        <ButtonComponent buttonText="Add Controller" buttonVariant="contained" buttonStyle={{borderRadius: "100px",}} startIcon={<AddIcon />} onClick={handleOpen} />
                        <ButtonComponent buttonText={<ListOutlinedIcon />} buttonVariant="outline" buttonStyle={{borderRadius: "10px",}} buttonClass="btn-small only-icon-btn" />
                    </Stack>
                </Box> */}
            </Box>
            <Box sx={{ mt: '30px' }}>
                {/* <h1 >Controller Data</h1> */}
                <ControllerTable controllerData={controllerData} AddUpdateController={AddUpdateController} />
            </Box>
            <AddControllers open={open} handleClose={handleClose} AddUpdateController={AddUpdateController} />
        </Box>
    )
}

export default Controllers