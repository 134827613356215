import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Box,
  Stack,
  Switch,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import AddControllers from "../Add controllers/AddControllers";
import { updateApi } from "../../../Api/Api";
import ConfirmationDialog from "../../../Components/ConfirmationDialog";
import { useSearch } from "../../../Context/SearchContext";
import toast from "react-hot-toast";
import { format, parseISO } from 'date-fns';

const ControllerTable = ({ controllerData, AddUpdateController }) => {
  const [open, setOpen] = useState(false);
  const [editValue, setEditValue] = useState(false);
  const [activeStatus, setActiveStatus] = useState(false);
  const [controllerId, setControllerId] = useState();
  const [switchStates, setSwitchStates] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const { searchQuery } = useSearch();
  const [filteredData, setFilteredData] = useState(controllerData);

  useEffect(() => {
    if (searchQuery) {
      const filteredResults = controllerData.filter((item) =>
        Object.values(item).some((value) => String(value).includes(searchQuery))
      );
      setFilteredData(filteredResults);
    } else {
      setFilteredData([]);
    }
  }, [searchQuery, controllerData]);

  const displayData = searchQuery ? filteredData : controllerData;

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#042835",
      color: "rgb(255,255, 255, 70%)",
      borderBottom: "1px solid #021A22",
      fontFamily: "Acumin Pro",
      fontWeight: 700,
      fontSize: 16,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      backgroundColor: "#03212b",
      color: "rgb(255,255, 255, 40%)",
      borderBottom: "1px solid #021A22",
      fontFamily: "Acumin Pro",
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const handleOpen = (id) => {
    setEditValue(true);
    setControllerId(id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEditValue(false);
    setControllerId(null);
  };

  const handleSwitchChange = (id, currentSwitchState) => {
    setEditValue(true);
    setControllerId(id);
    setActiveStatus(currentSwitchState)
    setSwitchStates((prevState) => ({
      ...prevState,
      [id]: !currentSwitchState,
    }));
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleDelete = (id) => {
    setControllerId(id);
    setEditValue(false);
    setOpenDialog(true);
  };

  const handleUpdate = async () => {
    setOpenDialog(false);
    const endpoint = `/controller/${controllerId}`;

    try {
      const requestData = editValue
        ? { is_actived: switchStates[controllerId] }
        : { is_deleted: true };

      const responseData = await updateApi(endpoint, requestData);
    
      if (responseData.data) {
        AddUpdateController();
        toast.success(editValue ? "Controller Updated" : "Controller Deleted");
      } else {
        console.log("Update/Delete failed");
        toast.error(editValue ? "Controller Updated Failed" : "Controller Deleted Failed");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <Box className="table-container">
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 800 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Controller Name</StyledTableCell>
              <StyledTableCell>Serial Number</StyledTableCell>
              <StyledTableCell>Mac Address</StyledTableCell>
              <StyledTableCell>Activation Date</StyledTableCell>
              <StyledTableCell>Deactivation Date</StyledTableCell>
              <StyledTableCell>Patient Name</StyledTableCell> {/* New Column */}
              <StyledTableCell>Status</StyledTableCell>
              <StyledTableCell>Actions</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {displayData.map((controller) => (
              <StyledTableRow key={controller.id}>
                <StyledTableCell component="th" scope="row">
                  {controller.controller_name}
                </StyledTableCell>
                <StyledTableCell>{controller.serial_no}</StyledTableCell>
                <StyledTableCell>{controller.mac_address}</StyledTableCell>
                <StyledTableCell>
                  {controller.activation_date && controller.is_actived
                    ? format(parseISO(controller.activation_date), 'MM-dd-yy HH:mm')
                    : 'N/A - Deactivated'}
                </StyledTableCell>
                <StyledTableCell>
                  {controller.deactivation_date && !controller.is_actived
                    ? format(parseISO(controller.deactivation_date), 'MM-dd-yy HH:mm')
                    : 'N/A - Activated'}
                </StyledTableCell>
                <StyledTableCell>
                  {controller.patient_name || 'N/A'} {/* Display Patient Name */}
                </StyledTableCell>
                <StyledTableCell>
                  <Switch
                    checked={controller.is_actived}
                    className="cstm-toggle-btn"
                    onChange={() =>
                      handleSwitchChange(controller.id, controller.is_actived)
                    }
                  />
                </StyledTableCell>
                <StyledTableCell>
                  <Stack
                    direction="row"
                    gap={2}
                    sx={{ justifyContent: "center" }}
                  >
                    <Button
                      variant="contained"
                      color="info"
                      className="btn-primary"
                      onClick={() => handleOpen(controller.id)}
                    >
                      Edit
                    </Button>
                    <Button
                      variant="contained"
                      color="error"
                      className="btn-delete"
                      onClick={() => handleDelete(controller.id)}
                    >
                      Delete
                    </Button>
                  </Stack>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <ConfirmationDialog
        open={openDialog}
        content={`Are you sure you really want to ${
          editValue ? (!activeStatus ? "active" : "deactivate") : "Delete"
        } this?`}
        onConfirm={handleUpdate}
        onClose={handleDialogClose}
      />
      <AddControllers
        open={open}
        handleClose={handleClose}
        editValue={editValue}
        controllerId={controllerId}
        AddUpdateController={AddUpdateController}
      />
    </Box>
  );
};

export default ControllerTable;
