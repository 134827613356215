import React, { useMemo } from "react";
import {
  Box,
  Grid,
  TextField,
  Typography,
  InputAdornment,
  Autocomplete,
  Stack,
  Divider,
  Select,
  MenuItem
} from "@mui/material";
import "./SetGoalComponet.css";
import ButtonComponent from "../../Components/ButtonComponent/ButtonComponent";
import { Add, Cancel } from "@mui/icons-material";

// type GoalType = {
//   label: string,
//   min: number,
//   max: number,
//   unit: string,
// };

const SetGoalComponent = () => {
  const [goals, setGoals] = React.useState([]);

  const filterGoalList = useMemo(() => {
    return goalList.filter((goal) => !goals.includes(goal));
  }, [goals]);

  const handleAddGoal = () => {
    const currentGoals = [...goals];
    setGoals([...currentGoals, filterGoalList[0]]);
  };

  const handleChangeGoal = (index, value) => {
    const currentGoals = [...goals];
    currentGoals[index] = value;
    setGoals(currentGoals);
  };

  const handleChangeGoalValue = (index, value) => {
    const currentGoals = [...goals];
    currentGoals[index].value = value;
    setGoals(currentGoals);
  };

  const handleChangeGoalComparison = (index, value) => {
    const currentGoals = [...goals];
    currentGoals[index].comp = value;
    setGoals(currentGoals);
  }

  const handleDeleteGoal = (index) => {
    const currentGoals = [...goals];
    currentGoals.splice(index, 1);
    setGoals(currentGoals);
  };

  const isError = (goal) => {
    return goal?.value < goal?.min || goal?.value > goal?.max;
  };

  const goalOptions = [ 'Less Than', 'Greater Than', 'Equals ']

  return (
    <Box mt={3}>
      <Box
        className="title-with-border text-primery text--left"
        position="relative"
      >
        <Grid container spacing={2}> 
          {/* Bedtime Continuation - Initiation Threshold */}
          <Grid item xs={6}> 
            <Box className="form-group"> 
              <TextField 
                label="Bedtime Continuation Threshold"
                variant="outlined"
              />
            </Box>
          </Grid>
          <Grid item xs={6}> 
            <Box className="form-group"> 
              <TextField 
                label="Bedtime Initiation Threshold"
                variant="outlined"
              />
            </Box>
          </Grid>
          {/* Nighttime Start - End */}
          <Grid item xs={6}> 
            <Box className="form-group"> 
              <TextField 
                label="Nighttime Start"
                variant="outlined"
              />
            </Box>
          </Grid>
          <Grid item xs={6}> 
            <Box className="form-group"> 
              <TextField 
                label="Nighttime End"
                variant="outlined"
              />
            </Box>
          </Grid>
        </Grid>

        <Typography component="h4" className="title">
          Goals
        </Typography>
      </Box>

      <Box mt={3}>
        {goals.map((goal, index) => (
          <Grid container spacing={3} key={index}>
            <Grid item lg={6}>
              <Autocomplete
                id="combo-box-demo"
                options={filterGoalList}
                getOptionLabel={(option) => option?.label}
                value={goal}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{
                      input: {
                        color: "#91D9B9",
                      },
                      button: {
                        color: "#91D9B9 !important",
                      },
                    }}
                  />
                )}
                onChange={(_, value) => handleChangeGoal(index, value)}
                sx={{
                  borderColor: "var(--white)",
                  color: "var(--primeryColor)",
                  border: "1px solid var(--whiteLight)",
                  borderRadius: "5px",
                  outline: "none !important",
                }}
              />
            </Grid>
            {/* Less Than, Greater Than, Equals */}
            <Grid item lg={2}> 
              <Box className="form-group"> 
                <Select 
                  value={goal?.comp} 
                  onChange={(event) => handleChangeGoalComparison(index, event?.target.value)} 
                  displayEmpty
                  fullWidth
                  sx={{
                    borderColor: "var(--white)",
                    color: "var(--primeryColor)",
                    border: "1px solid var(--whiteLight)",
                    borderRadius: "5px",
                    outline: "none !important",
                    textAlign: "left",
                  }}
                >
                  {goalOptions.map((option, index) => (
                    <MenuItem key={index} value={option}> 
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            </Grid>
            <Grid item lg={3.5}>
              <Box className="form-group">
                <TextField
                  value={goal?.value}
                  type="number"
                  disableUnderline
                  error={isError(goal)}
                  disabled={!goal?.label}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Box color="#777">{goal?.unit}</Box>
                      </InputAdornment>
                    ),
                  }}
                  onChange={(x) =>
                    handleChangeGoalValue(index, x?.target?.value)
                  }
                  sx={{
                    border: isError(goal)
                      ? "none"
                      : "1px solid var(--whiteLight)",
                    input: {
                      width: "100%",
                      color: "var(--primeryColor)",
                      borderRadius: "5px",
                      padding: "16px 14px",
                    },
                  }}
                />
              </Box>
            </Grid>
            <Grid item lg={0.5}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                height="100%"
                pb={2.5}
                onClick={() => handleDeleteGoal(index)}
              >
                <Cancel className="delete-icon" htmlColor="danger" />
              </Box>
            </Grid>
          </Grid>
        ))}
        {/* Buttons */}
        <Grid container spacing={1}> 
          <Grid item lg={11.5}> 
            <Box className="right btn-font-small"> 
              <Stack 
                direction="row" 
                spacing={{ xs: 1 }} 
                divider={<Divider orientation="vertical" flexItem />}
                justifyContent="flex-end"
                sx={{ 
                  maxWidth: 'fit-content',
                  marginLeft: 'auto'
                }} 
              >
                <ButtonComponent
                  buttonText="Add Goal"
                  buttonVariant="contained"
                  buttonStyle={{ borderRadius: "30px", width: "150px !important" }}
                  startIcon={<Add />}
                  onClick={handleAddGoal}
                />
                {goals.length > 0 && (
                  <ButtonComponent
                    buttonText="Save"
                    buttonVariant="contained"
                    // onClick={handleAddGoal}
                    buttonStyle={{
                      borderRadius: "30px",
                      color: "#ffffff",
                      padding: "5px 10px",
                      width: "150px !important"
                    }}
                    buttonClass="outline-btn only-icon-btn"
                  />
                )}
              </Stack>
            </Box>   
          </Grid> 
        </Grid>
      </Box>
    </Box>
  );
};

export default SetGoalComponent;

const goalList = [
  { label: "Pre-Sleep Routine", min: 0, max: 90, unit: "minutes", value: 0, comp: "" },
  { label: "Out of Bed", min: 0, max: 90, unit: "minutes", value: 0, comp: "" },
  { label: "Sleep Period", min: 0, max: 90, unit: "minutes", value: 0, comp: "" },
  { label: "Vocalizations", min: 0, max: 90, unit: "minutes", value: 0, comp: "" },
  { label: "Parent Interaction", min: 0, max: 90, unit: "minutes", value: 0, comp: "" },
  { label: "Sleep At Night", min: 0, max: 90, unit: "minutes", value: 0, comp: "" },
  { label: "Sleep Onset Delay", min: 0, max: 90, unit: "minutes", value: 0, comp: "" },
  { label: "Night Wakings", min: 0, max: 90, unit: "minutes", value: 0, comp: "" },
  { label: "Stereotypy", min: 0, max: 90, unit: "minutes", value: 0, comp: "" },
  { label: "Naptime Wakings", min: 0, max: 90, unit: "minutes", value: 0, comp: "" },
  {
    label: "Sitting or Standing",
    min: 0,
    max: 90,
    unit: "minutes",
    value: 0,
    comp: ""
  },
  {
    label: "Naptime Prep Duration",
    min: 0,
    max: 90,
    unit: "minutes",
    value: 0,
    comp: ""
  },
  {
    label: "Baby Leaves The Nursery",
    min: 0,
    max: 90,
    unit: "minutes",
    value: 0,
    comp: ""
  },
  { label: "Nap Time Duration", min: 0, max: 90, unit: "minutes", value: 0, comp: "" },
  {
    label: "Nighttime Bedtime Prep Duration",
    min: 0,
    max: 90,
    unit: "minutes",
    comp: ""
  },
];
