import { Box, IconButton, InputAdornment, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./Login.css";
import "../../Assets/Css/Responsive.css";
import loginLeft from "./../../Assets/Images/Login/login-left.png";
import logo from "./../../Assets/Images/Login/logo.svg";
import Input from "../../Components/InputComponent/Input";
import ButtonComponent from "../../Components/ButtonComponent/ButtonComponent";
import { useForm } from "react-hook-form";
import { postLogin } from "../../Api/Service";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../Context/AuthContext";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import toast, { Toaster } from "react-hot-toast";
import { basePath } from "../../routes/Config";
// import { login } from './authSlice'
// import { useDispatch } from "react-redux";

const Login = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  // const dispatch = useDispatch();

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (e) => {
    e.preventDefault();
  };
  const { login } = useAuth();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();


  const rememberMeEmail = localStorage.getItem('rememberedEmail');
  const rememberMePassword = localStorage.getItem('rememberedPassword');
  useEffect(() => {
    if (rememberMeEmail && rememberMePassword) {
      setValue('email', rememberMeEmail);
      setValue('password', rememberMePassword);
      setRememberMe(!rememberMe)
    }
  }, [rememberMeEmail, rememberMePassword, setValue, rememberMe])


  const onSubmit = async (data, event) => {
    event.preventDefault();
    const endpoint = '/login';

    if (rememberMe) {
      localStorage.setItem('rememberedEmail', data.email);
      localStorage.setItem('rememberedPassword', data.password);
    } else {
      localStorage.removeItem('rememberedEmail');
      localStorage.removeItem('rememberedPassword');
    }

    try {
      const responseData = await postLogin(endpoint, data);
      if (responseData.statusCode === 200) {
        login(responseData.accessToken, responseData.data);
        localStorage.setItem('token', responseData.accessToken);
        localStorage.setItem('user', JSON.stringify(responseData.data));
        window.history.replaceState({}, "", basePath); 
        navigate(basePath, { state: { from: `${basePath}login` } }); // changed from ${basePath}sensors-monitoring (PER-19)
        // navigate('/PercyApp_Frontend', { replace: true });
      } else {
        toast.error("Invalid User Credential");
      }
    } catch (error) {
      if(error?.response){
        toast.error(error?.response?.data.error);
      }
    }

    // try {
    //   const actionResult = await dispatch(login(data));
    //   if (login.fulfilled.match(actionResult)) {
    //     const responseData = actionResult.payload;
    //     localStorage.setItem('token', responseData.accessToken);
    //     localStorage.setItem('user', JSON.stringify(responseData.data));
    //     window.history.replaceState({}, "", `${basePath}event-monitoring`);
    //     navigate(`${basePath}event-monitoring`, { state: { from: `${basePath}/login` } });
    //   } else {
    //     toast.error("Invalid User Credential");
    //   }
    // } catch (error) {
    //   // Handle error
    //   if (error?.response) {
    //     toast.error(error?.response?.data.error);
    //   } else {
    //     toast.error("An error occurred");
    //   }
    // }
  };


  return (
    <Box className="login-container">
      <Toaster  position="top-center" reverseOrder={false} />
      <Box className="login-main">
        <Box className="left-section">
          <Box className="img-container">
            <Box className="text-container">
              <Typography component="h4" className="heading">
                Easy to observe{" "}
              </Typography>
              <Typography component="h4" className="heading">
                your daily sleep{" "}
              </Typography>
              <Typography component="h4" className="heading">
                routine.
              </Typography>
            </Box>
            <img src={loginLeft} alt="" />
          </Box>
        </Box>
        <Box className="right-section">
          <Box className="logo-box">
            <Link href="#">
              <img src={logo} alt="Logo" />
            </Link>
          </Box>
          <Box className="login-details">
            <Box className="heading--box">
              <Typography component="h2" className="text-primery heading">
                Hello again!
              </Typography>
              <Typography component="p" className="text-white">
                Welcome, so good to see you! Log in below.
              </Typography>
            </Box>
          </Box>
          <Box component="form" onSubmit={handleSubmit(onSubmit)}>
            <Box className="form-group">
              <Input
                label="Email"
                placeholder="Enter your Email"
                type="email"
                name="email"
                register={register}
                requiredFeild="requiredFeild"
                validationRules={{
                  required: 'Email is required.',
                  pattern: {
                    value: /^\S+@\S+$/i,
                    message: 'Invalid email format. Please enter a valid email address.',
                  },
                }}
              />
              {errors.email && (
                <p className="error-message">{errors.email.message}</p>
              )}
            </Box>

            <Box className="form-group">
              <Box className="pass-btn-group">
                <Input
                  label="Password"
                  placeholder="Enter your Password"
                  type={showPassword ? "text" : "password"}
                  name="password"
                  requiredFeild="requiredFeild"
                  register={register}
                  validationRules={{
                    required: "Password is required.",
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="password"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              {errors.password && (
                <p className="error-message">{errors.password.message}</p>
              )}
            </Box>

            <Box className="form-group text--left">
              <input
                type="checkbox"
                checked={rememberMe}
                id="login-remeber"
                onChange={() => setRememberMe(!rememberMe)}
              />
              <label htmlFor="login-remeber" className="text-white remember-label">Remember Me</label>
            </Box>

            <Box className="form-group">
              <ButtonComponent
                buttonText="Login"
                buttonVariant="contained"
                customStyle={{
                  marginTop: "10px",
                  backgroundColor: "green",
                  color: "white",
                }}
                type="submit"
              />
            </Box>
          </Box>
          <Box className="text--right">
            <Link to={`${basePath}forget-password`} className="text-white no-underline border-underline">
              Forgot password ?
            </Link>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Login;


