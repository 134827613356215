import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

const PhysicianCard = ({ PhysiciansDetails }) => {
  return (
    <Card>
      <CardContent>
        <Typography variant="h5" component="div">
          First Name: {PhysiciansDetails.first_name}
        </Typography>
        <Typography variant="h5" component="div">
          Middle Name: {PhysiciansDetails.middle_name}
        </Typography>
        <Typography variant="h5" component="div">
          Last Name: {PhysiciansDetails.last_name}
        </Typography>
        <Typography variant="h5" color="textSecondary">
          Email: {PhysiciansDetails.email_id}
        </Typography>
        <Typography variant="h5" color="textSecondary">
          Phone Number: {PhysiciansDetails.phone_no}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default PhysicianCard;
