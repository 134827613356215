import React from 'react';
import { Box, List, ListItem, ListItemIcon, ListItemText, ListItemButton, Divider } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import "./SideBar.css";
import "../../Assets/Css/Responsive.css";
import logo from "./../../Assets/Images/Login/logo.svg";
import { ReactComponent as DashboardIcon } from "./../../Assets/Images/Sidebar/dashboard-icon.svg";
import { ReactComponent as MyPatientsIcon } from "./../../Assets/Images/Sidebar/my-patients-icon.svg";
import { ReactComponent as GoalSetupIcon } from "./../../Assets/Images/Sidebar/goals-setup-icon.svg";
import { ReactComponent as SetupMonitoringIcon } from "./../../Assets/Images/Sidebar/setup-monitoring-icon.svg";
import { ReactComponent as EventMonitoringIcon } from "./../../Assets/Images/Sidebar/event-monitoring-icon.svg";
import { basePath } from '../../routes/Config';



// const patientItems = [
//     { icon: DashboardIcon, primary: "Dashboard", path: "/" },
//     { icon: MyPatientsIcon, primary: "My Patients", path: "/my-patients" },
//     { icon: GoalSetupIcon, primary: "Goals Setup", path: "/goals-setup" },
//     { icon: SetupMonitoringIcon, primary: "Setup Monitoring", path: "/setup-monitoring" },
//     { icon: EventMonitoringIcon, primary: "Event Monitoring", path: "/event-monitoring" },
//   ];


//   const adminItems = [
//     { icon: DashboardIcon, primary: "Dashboard", path: "/" },
//     { icon: MyPatientsIcon, primary: "My Patients", path: "/my-patients" },
//     { icon: GoalSetupIcon, primary: "Goals Setup", path: "/goals-setup" },
//     { icon: SetupMonitoringIcon, primary: "Setup Monitoring", path: "/setup-monitoring" },
//     { icon: EventMonitoringIcon, primary: "Event Monitoring", path: "/event-monitoring" },
//     { icon: GoalSetupIcon, primary: "Hospitals", path: "/my-hospitals" },
//     { icon: SetupMonitoringIcon, primary: "Physician", path: "/my-physician" },
//     { icon: EventMonitoringIcon, primary: "Super Admin", path: "/my-users" },
//     { icon: EventMonitoringIcon, primary: "Controller", path: "/my-controllers" },


// const menuItems = [
//     { icon: DashboardIcon, primary: "Dashboard", path: "/PercyApp_Frontend" },
//     { icon: MyPatientsIcon, primary: "My Patients", path: "my-patients" },
//     { icon: GoalSetupIcon, primary: "Goals Setup", path: "goals-setup" },
//     { icon: SetupMonitoringIcon, primary: "Setup Monitoring", path: "setup-monitoring" },
//     { icon: EventMonitoringIcon, primary: "Event Monitoring", path: "event-monitoring" },
//     { icon: GoalSetupIcon, primary: "Hospitals", path: "my-hospitals" },
//     { icon: SetupMonitoringIcon, primary: "Physician", path: "my-physician" },
//     { icon: EventMonitoringIcon, primary: "Super Admin", path: "my-users" },
//     { icon: EventMonitoringIcon, primary: "Controller", path: "my-controllers" },
//   ];

  const adminMenuItems = [
    { icon: DashboardIcon, primary: "Dashboard", path: basePath },
    { icon: MyPatientsIcon, primary: "My Patients", path: `${basePath}my-patients` },
    // { icon: GoalSetupIcon, primary: "Goals Setup", path: `${basePath}goals-setup` }, -> moved to tab on my patients page
    // { icon: SetupMonitoringIcon, primary: "Setup Monitoring", path: `${basePath}setup-monitoring` },
    // { icon: EventMonitoringIcon, primary: "View Data", path: `${basePath}sensors-monitoring` },
    // { icon: EventMonitoringIcon, primary: "Event Monitoring", path: `${basePath}event-monitoring`},
    // { icon: EventMonitoringIcon, primary: "View Data", path: `${basePath}graph-monitoring`},
    // { icon: EventMonitoringIcon, primary: "Video Monitoring", path: `${basePath}video-monitoring`},
    { icon: GoalSetupIcon, primary: "Hospitals", path: `${basePath}my-hospitals` },
    { icon: SetupMonitoringIcon, primary: "Physician", path: `${basePath}my-physician` },
    { icon: EventMonitoringIcon, primary: "Super Admin", path: `${basePath}my-users` },
    { icon: EventMonitoringIcon, primary: "Controller", path: `${basePath}my-controllers` },
  ];

  const physicianMenuItems = [
    { icon: DashboardIcon, primary: "Dashboard", path: basePath },
    { icon: MyPatientsIcon, primary: "My Patients", path: `${basePath}my-patients` },
    // { icon: EventMonitoringIcon, primary: "View Data", path: `${basePath}graph-monitoring`},
    { icon: EventMonitoringIcon, primary: "Controller", path: `${basePath}my-controllers` },
    // { icon: EventMonitoringIcon, primary: "View Data", path: `${basePath}sensors-monitoring` },
  ];

  

const SideBar = () => {
    const location = useLocation();


    const user = JSON.parse(localStorage.getItem('user'))
    // admin role ID = 1
    // phys role ID = 0
    // console.log('user-----------------', user)

    // const menuItems = user.is_admin_user == true ? adminItems : patientItems;

    return (
        <Box className="sidebar-container">
            <Box className="logo-container">
                <img src={logo} alt='' />
            </Box>
            <Box className="sidebar-menu-container">
                <List>
                    {user && user.role_id === 1 && adminMenuItems.map((menuItem, index) => (
                        <React.Fragment key={index}>
                            <ListItem disablePadding className='menu-div'>
                                <ListItemButton component={Link} to={menuItem.path} className={( location.pathname === menuItem.path ? 'active' : '')}>
                                    <ListItemIcon className={'sidebar-icon-box ' + (menuItem.path === basePath ? 'svg-stroke' : '')} >
                                        <menuItem.icon />
                                    </ListItemIcon>
                                    <ListItemText primary={menuItem.primary} className='menu-name' />
                                </ListItemButton>
                            </ListItem>
                            <Divider className='divider-color' />
                        </React.Fragment>
                    ))}
                    {user && user.role_id === 0 && physicianMenuItems.map((menuItem, index) => (
                        <React.Fragment key={index}>
                            <ListItem disablePadding className='menu-div'>
                                <ListItemButton component={Link} to={menuItem.path} className={( location.pathname === menuItem.path ? 'active' : '')}>
                                    <ListItemIcon className={'sidebar-icon-box ' + (menuItem.path === basePath ? 'svg-stroke' : '')} >
                                        <menuItem.icon />
                                    </ListItemIcon>
                                    <ListItemText primary={menuItem.primary} className='menu-name' />
                                </ListItemButton>
                            </ListItem>
                            <Divider className='divider-color' />
                        </React.Fragment>
                    ))}
                </List>
            </Box>
        </Box>
    );
};

export default SideBar;
