// HospitalDetail.js
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { GetApiParam } from '../../Api/Api';
import { Box, CircularProgress } from '@mui/material';
import UserCard from './components/UserCard';

const UsersDetails = () => {
  const { id } = useParams();
  const [usersDetails, setUsersDetails] = useState({});
  const [loading, setLoading] = useState(true);
  // const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUsersDetails = async () => {
      const endpoint = '/user/';
      try {
        const response = await GetApiParam(endpoint, { id: id });
        console.log('----------------------------------', response)
        setUsersDetails(response.data.data[0]);

        setLoading(false);
      } catch (error) {
        // setError(error);
        setLoading(false);
      }
    };
    fetchUsersDetails();
  }, [id]);

  console.log('UsersDetails---------------', usersDetails)
  if (loading) {
    return <CircularProgress style={{ margin: '20px' }} />;
  }



  return (
    <Box>
      Hello
      <UserCard UsersDetails={usersDetails} />
    </Box>
  );
};

export default UsersDetails;
