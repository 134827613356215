// ControllerDetail.js
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { GetApiParam } from '../../Api/Api';
import { Box, CircularProgress } from '@mui/material';
import ControllerCard from './components/ControllerCards';

const ControllerDetail = () => {
  const { id } = useParams();
  const [controllerDetail, setControllerDetail] = useState({});
  const [loading, setLoading] = useState(true);
  // const [error, setError] = useState(null);

  useEffect(() => {
    const fetchControllerDetail = async () => {
      const endpoint = '/controller/';
      try {
        const response = await GetApiParam(endpoint , { id: id });
        console.log('----------------------------------', response)
        setControllerDetail(response.data.data[0]);
        
        setLoading(false);
      } catch (error) {
        // setError(error);
        setLoading(false);
      }
    };
    fetchControllerDetail();
  }, [id]);

  console.log('controllerDetail---------------', controllerDetail)
  if (loading) {
    return <CircularProgress style={{ margin: '20px' }} />;
}



  return (
    <Box>
      <ControllerCard controllerDetail={controllerDetail} />
    </Box>
  );
};

export default ControllerDetail;
