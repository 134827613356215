import axios from 'axios';
// const REACT_APP_BACKEND_URL = "http://52.87.56.163/stagebck/api"
// `${process.env.REACT_APP_BACKEND_URL}`
const apiService = axios.create(
  {
    baseURL: `${process.env.REACT_APP_BACKEND_URL}`,
    headers: {
      'Content-Type': 'application/json',
    },
  }
);

export const postLogin = async (endpoint, data) => {
  try {
    const response = await apiService.post(endpoint, data);
    return response.data;
  } catch (error) {
    console.error('API request failed:', error);
    throw error;
  }
};

export const postRequest = async (endpoint, config, data) => {
  try {
    const response = await apiService.post(endpoint, data, config);
    return response.data;
  } catch (error) {
    console.error('API request failed:', error);
    throw error;
  }
};


// apiService.js

// import axios from 'axios';

// const BASE_URL = 'https://api.example.com';

// const apiService = {
//   get: async (endpoint) => {
//     try {
//       const response = await axios.get(`${BASE_URL}/${endpoint}`);
//       return response.data;
//     } catch (error) {
//       console.error('API request failed:', error);
//       throw error; // Re-throw the error to be caught by the caller
//     }
//   },

//   post: async (endpoint, data) => {
//     try {
//       const response = await axios.post(`${BASE_URL}/${endpoint}`, data);
//       return response.data;
//     } catch (error) {
//       console.error('API request failed:', error);
//       throw error;
//     }
//   },

//   put: async (endpoint, data) => {
//     try {
//       const response = await axios.put(`${BASE_URL}/${endpoint}`, data);
//       return response.data;
//     } catch (error) {
//       console.error('API request failed:', error);
//       throw error;
//     }
//   },

//   delete: async (endpoint) => {
//     try {
//       const response = await axios.delete(`${BASE_URL}/${endpoint}`);
//       return response.data;
//     } catch (error) {
//       console.error('API request failed:', error);
//       throw error;
//     }
//   },
// };

// export default apiService;
