
import { useEffect, useCallback, useRef } from "react";
import { Controller, useForm } from "react-hook-form"



const UserDetails = () => {
    const { register, handleSubmit, setValue, control, watch, trigger } = useForm({
        defaultValues: {
            gender: "male"
        }
    })
    const watchedFields = watch();

    useEffect(() => {
        setValue('gender', 'male');
    }, [setValue]);


    const onSubmit = (formData) => {
        console.log("formData", formData)
    }


    function useDebouncedCallback(callback, delay) {
        const timerRef = useRef(null);

        return useCallback(
            (...args) => {
                clearTimeout(timerRef.current);
                timerRef.current = setTimeout(() => {
                    callback(...args);
                }, delay);
            },
            [callback, delay]
        );
    }
    trigger();
    const autosave = useDebouncedCallback(
        useCallback(async () => {
            try {
                await trigger()  // Trigger validation
                const isValid = await handleSubmit(onSubmit)(); // Validate and submit
                if (isValid) {
                    console.log("Form autosaved:", watchedFields);
                    // Replace the console.log with your actual autosave function 
                }
            } catch (error) {
                console.error("Autosave error:", error);
            }
        }, [trigger, watchedFields]),
        1000 // Debounce for 1 second
    );

    useEffect(() => {
        autosave();
    }, [watchedFields, autosave]);

    return (
        <div style={{ backgroundColor: "white", borderRadius: '20px', padding: '20px' }} >
            <form style={{ marginTop: "20px", textAlign: 'left' }} onSubmit={handleSubmit(onSubmit)}>
                First Name
                <input
                    type="text"
                    {...register("firstName", { required: true })}
                />
                <br /> <br />

                <label>Last Name</label>
                <input
                    type="text"
                    {...register("lastName", { required: true })}
                />
                <br /> <br />

                <label>Email Address</label>
                <input
                    type="email"
                    {...register("email", { required: true })}
                />
                <br /> <br />

                <label>Date of Birth</label>
                <input
                    type="date"
                    {...register("dob", { required: true })}
                />

                <br /> <br />
                <label>Age</label>
                <input
                    type="number"
                    {...register("age", { required: true })}
                />
                <br /> <br />

                <p>Please select your Gender:</p>
                {/* <input type="radio" value="male" {...register("gender")} />
                <label >Male</label>
                <input type="radio" value="female" {...register("gender")} />
                <label >Female</label> */}
                <Controller
                    name="gender"
                    control={control}
                    render={({ field }) => (
                        <div>
                            <input {...field} type="radio" value="male" checked="checked" />
                            <label>Male</label>
                            <input {...field} type="radio" value="female" />
                            <label>Female</label>
                        </div>
                    )}
                />

                <br /> <br />
                <label>Choose a Language:</label>
                <select multiple {...register("languages", { required: true })}>
                    <option value="javascript">Javascript</option>
                    <option value="ruby">Ruby</option>
                    <option value="python">Python</option>
                    <option value="jave">Java</option>
                </select>

                <br /> <br />
                <input type="checkbox" value="policy" {...register("policy", { required: true })} />
                <label> I accept the policy</label>

                <br /> <br />
                <button style={{ backgroundColor: "skyBlue" }} type="submit">Submit</button>
            </form>
        </div>
    )
}

export default UserDetails


