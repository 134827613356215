import React, { useEffect,  useState } from "react";
import "../MyPatients/MyPatients.css";
import "../../Assets/Css/Responsive.css";
import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import ButtonComponent from "../../Components/ButtonComponent/ButtonComponent";
import AddIcon from "@mui/icons-material/Add";
import AddHospitals from "./Add hospitals/AddHospitals";
import { GetApi } from "../../Api/Api";
import HospitalTable from "./components/HospitalTable";
import ListOutlinedIcon from "@mui/icons-material/ListOutlined";
import { Toaster } from "react-hot-toast";


const Hospitals = () => {
  const [open, setOpen] = useState(false);
  const [hospitalData, setHospitalData] = useState([]);
  const [loading, setLoading] = useState(true);
  // const [error, setError] = useState(null);


  const fetchHospitalData = async () => {
    const endpoint = "/hospitals/";
    try {
      const response = await GetApi(endpoint);
      setHospitalData(response.data.data);
      localStorage.setItem("hospitalData", JSON.stringify(response.data.data));
      setLoading(false);
    } catch (error) {
      // setError(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchHospitalData();
  }, []);


  const AddUpdateHospital = () => {
    fetchHospitalData();
  };

  if (loading) {
    return <CircularProgress style={{ margin: "20px" }} />;
  }

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  }

    return (
      <Box>
        <Toaster position="top-right" reverseOrder={false} />
        <Box className="my-patients-topbar" mt={3}>
          <Box>
            <Typography className="title" component="h4">
              Hospital
            </Typography>
          </Box>
          <Box className="right btn-font-small">
            <Stack direction="row" gap={2}>
              <ButtonComponent
                buttonText="Add Hospital"
                buttonVariant="contained"
                buttonStyle={{ borderRadius: "100px" }}
                startIcon={<AddIcon />}
                onClick={handleOpen}
              />
              <ButtonComponent
                buttonText={<ListOutlinedIcon />}
                buttonVariant="outline"
                buttonStyle={{ borderRadius: "10px" }}
                buttonClass="btn-small only-icon-btn"
              />
            </Stack>
          </Box>
        </Box>
        <Box sx={{ mt: "30px" }}>
          <HospitalTable
            hospitalData={hospitalData}
            AddUpdateHospital={AddUpdateHospital}
          />
        </Box>
        <AddHospitals
          open={open}
          handleClose={handleClose}
          AddUpdateHospital={AddUpdateHospital}
        />
      </Box>
    );
  };

  export default Hospitals;
