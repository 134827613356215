import './App.css';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './Redux/Store/store';
import AppRoutes from './routes/router';
import { SearchProvider } from './Context/SearchContext';
import { TimeZoneProvider } from './Context/TimeZoneContext';
import { DeviceProvider } from './Context/DeviceContext';

function App() {
  return (
    <Provider store={store}>
      <SearchProvider>
        <TimeZoneProvider>
          <DeviceProvider>
            <BrowserRouter>
              <div className="App">
                <AppRoutes />
              </div>
            </BrowserRouter>
          </DeviceProvider>
        </TimeZoneProvider>
      </SearchProvider>
    </Provider>
  );
}

export default App;
