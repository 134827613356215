import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Box,
  Stack,
  Switch,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import ConfirmationDialog from "../../../../Components/ConfirmationDialog";
import { updateApi } from "../../../../Api/Api";
import { useSearch } from "../../../../Context/SearchContext";
import { AddPatients } from "../../components";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { basePath } from "../../../../routes/Config";

const PatientTable = ({ patientData, hospitalData, AddUpdatePatient }) => {
  const [open, setOpen] = useState(false);
  const [editValue, setEditValue] = useState(false);
  const [activeStatus, setActiveStatus] = useState(false);
  const [patientId, setPatientId] = useState();
  const [switchStates, setSwitchStates] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const { searchQuery } = useSearch();
  const [filteredData, setFilteredData] = useState(patientData);
  const navigate = useNavigate();

  // Get user data from local storage
  const user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    if (searchQuery) {
      const filteredResults = patientData.filter((item) =>
        Object.values(item).some((value) =>
          String(value).toLowerCase().includes(searchQuery)
        )
      );
      setFilteredData(filteredResults);
    } else {
      setFilteredData([]);
    }
  }, [searchQuery, patientData]);

  const displayData = searchQuery ? filteredData : patientData;

  const handleOpen = (id) => {
    setEditValue(true);
    setPatientId(id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEditValue(false);
    setPatientId(null);
  };

  const handleSwitchChange = (id, currentSwitchState) => {
    setEditValue(true);
    setPatientId(id);
    setActiveStatus(currentSwitchState);
    setSwitchStates((prevState) => ({
      ...prevState,
      [id]: !currentSwitchState,
    }));
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleDelete = (id) => {
    setPatientId(id);
    setEditValue(false);
    setOpenDialog(true);
  };

  const handlePatientDetails = (id) => {
    navigate(`${basePath}my-patients/${id}`);
  };

  const handleUpdate = async () => {
    setOpenDialog(false);
    const endpoint = `/patient/${patientId}`;

    try {
      const requestData = editValue
        ? { is_actived: switchStates[patientId] }
        : { is_deleted: true };

      const responseData = await updateApi(endpoint, requestData);

      if (responseData.data) {
        AddUpdatePatient();
        toast.success(editValue ? "Patient Updated" : "Patient Deleted");
      } else {
        console.log("Update/Delete failed");
        toast.error(
          editValue ? "Patient Updated Failed" : "Patient Deleted Failed "
        );
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <Box>
      <TableContainer component={Paper}>
        <Table style={{ borderCollapse: "collapse", minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <StyledTableCell>Title</StyledTableCell>
              <StyledTableCell>Patient Name</StyledTableCell>
              <StyledTableCell>Patient Code</StyledTableCell>
              <StyledTableCell>Email</StyledTableCell>
              <StyledTableCell>Phone Number</StyledTableCell>
              {user && user.role_id !== 0 && (
                <StyledTableCell>Physician</StyledTableCell>
              )}
              <StyledTableCell>Status</StyledTableCell>
              <StyledTableCell>Actions</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {displayData.map((patient, index) => (
              <StyledTableRow sx={{ cursor: "pointer" }} key={patient.id}>
                <StyledTableCell
                  onClick={() => handlePatientDetails(patient.id)}
                  component="th"
                  scope="row"
                >
                  {patient.title}
                </StyledTableCell>
                <StyledTableCell
                  onClick={() => handlePatientDetails(patient.id)}
                >
                  {patient.patient_name}
                </StyledTableCell>
                <StyledTableCell
                  onClick={() => handlePatientDetails(patient.id)}
                >
                  {patient.patient_code}
                </StyledTableCell>
                <StyledTableCell
                  onClick={() => handlePatientDetails(patient.id)}
                >
                  {patient.email_id}
                </StyledTableCell>
                <StyledTableCell
                  onClick={() => handlePatientDetails(patient.id)}
                >
                  {patient.phone_no}
                </StyledTableCell>
                {user && user.role_id !== 0 && (
                  <StyledTableCell
                    onClick={() => handlePatientDetails(patient.id)}
                  >
                    <p> Dr. </p> {patient.physician_name}
                  </StyledTableCell>
                )}
                <StyledTableCell>
                  <Switch
                    checked={patient.is_actived}
                    className="cstm-toggle-btn"
                    onChange={() =>
                      handleSwitchChange(patient.id, patient.is_actived)
                    }
                  />
                </StyledTableCell>
                <StyledTableCell>
                  <Stack direction="row" gap={2}>
                    <Button
                      variant="contained"
                      color="info"
                      className="btn-primary"
                      onClick={() => handleOpen(patient.id)}
                    >
                      Edit
                    </Button>
                    <Button
                      variant="contained"
                      color="error"
                      className="btn-delete"
                      onClick={() => handleDelete(patient.id)}
                    >
                      Delete
                    </Button>
                  </Stack>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <ConfirmationDialog
        open={openDialog}
        // title={`${editValue ? "Update" : "Delete"} patient`}
        content={`Are you sure you really want to ${
          editValue ? (!activeStatus ? "active" : "deactive") : "Delete"
        } this ?`}
        onConfirm={handleUpdate}
        onClose={handleDialogClose}
      />
      <AddPatients
        open={open}
        handleClose={handleClose}
        editValue={editValue}
        patientId={patientId}
        hospitalData={hospitalData}
        AddUpdatePatient={AddUpdatePatient}
      />
    </Box>
  );
};

export default PatientTable;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#042835",
    color: "rgb(255,255, 255, 70%)",
    borderBottom: "1px solid #021A22",
    fontFamily: "Acumin Pro",
    fontWeight: 700,
    fontSize: 16,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    backgroundColor: "#03212b",
    color: "rgb(255,255, 255, 40%)",
    borderBottom: "1px solid #021A22",
    fontFamily: "Acumin Pro",
    // fontSize: 16,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
