import { Box, Typography } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { useCallback, useEffect, useState } from "react";
import { parseDate } from "../../../../utils/helpers";
import { getPatientEvents } from "../../../../Api/AdapterApi";
import EventPill from "./EventPill";
import EventModal from "./EventModal";

const EventsCard = ({ patientId }) => {
  const [day, setDay] = useState(null);
  const [data, setData] = useState(null);

  const [selectedEvent, setSelectedEvent] = useState(false);

  const fetchEventsData = useCallback(async () => {
    if (!patientId || !day) return;

    const params = {
      patient_id: patientId,
      day,
    };
    const response = await getPatientEvents(params);

    if (response?.events) {
      setData(response?.events);
    }
  }, [patientId, day]);

  useEffect(() => {
    if (!day) return;
    fetchEventsData();
  }, [fetchEventsData, day]);

  const handleDayChange = (date) => {
    if (!date) return;
    const formattedDate = parseDate(date);
    setDay(formattedDate);
  };

  const handleOnClickEvent = (event) => {
    if (event?.graphs.length === 0) return;

    setSelectedEvent(event);
  };

  return (
    <>
      <Box id="event-container">
        <Box className="my-patients-topbar" mt={3}>
          <Typography className="title" component="h4">
            Events
          </Typography>
          <Box display="flex" gap="20px">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Box className="select-option">
                <DesktopDatePicker
                  disableFuture
                  format="MM-dd-yy"
                  onAccept={handleDayChange}
                />
              </Box>
            </LocalizationProvider>
          </Box>
        </Box>

        {!data && (
          <Box
            display="flex"
            alignItems="center"
            id="timeline-container"
            height="100%"
            pt={2}
            bgcolor="#042835"
            justifyContent="center"
            p={5}
          >
            <Typography component="h4" className="text-primery" align="center">
              {!day ? "Select a day" : "No data"}
            </Typography>
          </Box>
        )}
        {data && data.length > 0 && (
          <Box sx={wrapperStyle}>
            {data.map((event, index) => {
              const hasGraph = event?.graphs && event.graphs.length > 0;

              return (
                <Box
                  key={index}
                  onClick={() => handleOnClickEvent(event)}
                  sx={{
                    ...pillWrapperStyle,
                    cursor: hasGraph ? "pointer" : "initial",
                    "&:hover": {
                      border: hasGraph ? "3px solid #91D9B9" : "1px solid #91D9B9",
                    },
                  }}
                >
                  <EventPill event={event} />
                </Box>
              );
            })}
          </Box>
        )}
      </Box>
      <EventModal
        open={!!selectedEvent}
        onClose={() => setSelectedEvent(false)}
        event={selectedEvent}
        patientId={patientId}
      />
    </>
  );
};

const wrapperStyle = {
  display: "flex",
  flexWrap: "wrap",
  backgroundColor: "#042835",
};

export default EventsCard;

const pillWrapperStyle = {
  flex: "1 0 21%",
  margin: "16px",
  height: "74px",
  border: "1px solid #91D9B9",
  borderRadius: "60px",
  display: "flex",
  alignItems: "center",
  px: "12px",
  justifyContent: "space-between",
  color: "#91D9B9",
};
