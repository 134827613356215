import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import "./Dashboard.css";
import "../../Assets/Css/Responsive.css";
import DashboardCard from '../../Components/DashboardComponents/DashboardCard/DashboardCard';
import mypatients from "../../Assets/Images/Dashboard/my-patients.png";
import patientsbaseline from "../../Assets/Images/Dashboard/patients-baseline.png";
import patientsmaintenance from "../../Assets/Images/Dashboard/patients-maintenance.png";
import patientstreatment from "../../Assets/Images/Dashboard/patients-treatment.png";
import { GetApi } from "../../Api/Api.js";
import { Toaster } from 'react-hot-toast';
import ControllersTable from './components/controllerStatusTable'; 

const Dashboard = () => {
    const [assignedCount, setAssignedCount] = useState(0);
    const [unassignedCount, setUnassignedCount] = useState(0);
    const [controllerData, setControllerData] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate(); // Initialize useNavigate
    const [patientDataLength, setPatientDataLength] = useState(0);

    const fetchControllerCounts = async () => {
        const endpoint = '/controllers-count';
        try {
            const response = await GetApi(endpoint);
            if (response.status === 200) {
                const data = response.data;
                setAssignedCount(data.assignedCount);
                setUnassignedCount(data.unassignedCount);
            } else if (response.status === 401) {
                navigate('/login'); // Redirect to login page if unauthorized
            } else {
                console.error('Error fetching controller counts:', response.statusText);
            }
        } catch (error) {
            console.error('Error fetching controller counts:', error);
            if (error.response && error.response.status === 401) {
                navigate('/login'); // Redirect to login page if unauthorized
            }
        } finally {
            setLoading(false);
        }
    };

    const fetchControllerData = async () => {
        const endpoint = '/controllers/';
        try {
            const response = await GetApi(endpoint);
            console.log(response, 'response------------------')
            setControllerData(response.data.data);
            setLoading(false);
        } catch (error) {
            // setError(error);
            setLoading(false);
        }
    };

    const fetchPatients = async () => {
        const endpoint = '/patients';
        try {
            const response = await GetApi(endpoint);
            if (response.data.data) {
                setPatientDataLength(response.data.data.length);
            }
        } catch (error) {
            console.error('Error fetching patient data: ', error);
        }
    }

    useEffect(() => {
        fetchPatients();
        fetchControllerCounts();
        fetchControllerData();
    }, []);

    if (loading) {
        return <CircularProgress style={{ margin: "20px" }} />;
    }

    let patientCount = patientDataLength;
    if (patientDataLength < 10) {
        patientCount = '0' + patientDataLength;
    }

    return (
        <Box>
            <Toaster position="top-right" reverseOrder={false} />
            <Box mt={3}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={3}>
                        {patientDataLength && (
                            <DashboardCard icon={mypatients} title="My Patients" count={patientCount}
                            customStyle={{ background: '#FFA6C7' }} />
                        )}
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <DashboardCard title="Patients in Baseline" icon={patientsbaseline} count="00" customStyle={{ background: '#91D9B9' }} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <DashboardCard title="Patients in Treatment" icon={patientsmaintenance} count="00" customStyle={{ background: '#FFC548' }} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <DashboardCard title="Patients in Maintenance" icon={patientstreatment} count="01" customStyle={{ background: '#FA8352' }} />
                    </Grid>
                </Grid>
            </Box>
            <Box className="dash-graph-container" mt={3}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={8} xl={8}>
                        <Box className="left-section">
                            <Typography className='heading' component="h4">Controller Status</Typography>
                            <ControllersTable controllerData={controllerData} />
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4} xl={4}>
                        <Box className="right-section">
                            <Typography className='heading' component="h4">Controller Allocations</Typography>
                            <Box mt={1} className="controller-status-card">
                                <Box className="cont-card">
                                    <Typography className='total-assig-contoller' component="h4">Total assigned controller</Typography>
                                    <Typography className='count' component="h2">{assignedCount}</Typography>
                                </Box>
                                <Box className="cont-card" mt={3}>
                                    <Typography className='total-assig-contoller' component="h4">Total unassigned controller</Typography>
                                    <Typography className='count' component="h2">{unassignedCount}</Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}

export default Dashboard;
