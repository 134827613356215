// controllerStatusTable.js
import React, { useEffect, useState } from 'react';
import {
  Box,
  CircularProgress,
  Typography,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Switch,
  Button,
  Stack
} from '@mui/material';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
//import { GetApi, updateApi } from '../../../Api/Api';
import { GetApi } from '../../../Api/Api';
import ConfirmationDialog from "../../../Components/ConfirmationDialog";
import toast from "react-hot-toast";
import { useNavigate } from 'react-router-dom';
import { basePath } from '../../../routes/Config';

// Define the styled components
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#042835",
    color: "rgb(255,255, 255, 70%)",
    borderBottom: "1px solid #021A22",
    fontFamily: "Acumin Pro",
    fontWeight: 700,
    fontSize: 16,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    backgroundColor: "#03212b",
    color: "rgb(255,255, 255, 40%)",
    borderBottom: "1px solid #021A22",
    fontFamily: "Acumin Pro",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const ControllersTable = ({ controllerData }) => {
  //const [controllersData, setControllersData] = useState([]);
  console.log("controllerData:",controllerData);
  //const [loading, setLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [controllerId, setControllerId] = useState();
  const [activeStatus, setActiveStatus] = useState(false);
  const [switchStates, setSwitchStates] = useState({});
  const navigate = useNavigate();

  /** 
  const fetchControllers = async () => {
    const endpoint = '/controllers';
    try {
      const response = await GetApi(endpoint);
      if (response.data) {
        setControllersData(response.data);
      } else {
        console.error('Error fetching controllers data:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching controllers data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchControllers();
  }, []);
  **/

  const handleSwitchChange = (id, currentSwitchState) => {
    setControllerId(id);
    setActiveStatus(currentSwitchState);
    setSwitchStates((prevState) => ({
      ...prevState,
      [id]: !currentSwitchState,
    }));
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  /** 
  const handleUpdate = async () => {
    setOpenDialog(false);
    const endpoint = `/controller/${controllerId}`;
    try {
      const requestData = { is_actived: switchStates[controllerId] };
      const responseData = await updateApi(endpoint, requestData);
      if (responseData.data) {
        AddUpdateController();
        toast.success("Controller Updated");
      } else {
        console.log("Update failed");
        toast.error("Controller Update Failed");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  **/

  const handleControllerDetails = (id) => {
    navigate(`${basePath}controllers/${id}`);
  };

  // if (loading) {
  //   return <CircularProgress style={{ margin: '20px' }} />;
  // }

  return (
    <Box>
      <TableContainer component={Paper}>
        <Table style={{ borderCollapse: "collapse", minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <StyledTableCell>Controller Id</StyledTableCell>
              <StyledTableCell>Assigned Patient</StyledTableCell>
              <StyledTableCell>Last Message Time</StyledTableCell>
              <StyledTableCell>Status</StyledTableCell>
            </TableRow>
          </TableHead>
          
          <TableBody>
            {(controllerData) && controllerData.map((controller) => (
              <StyledTableRow key={controller.id}>
                <StyledTableCell component="th" scope="row">
                  {controller.controller_name}
                </StyledTableCell>
                <StyledTableCell>
                  {controller.patient_name || <i>Unavailable</i>} 
                </StyledTableCell>
                <StyledTableCell>
                  {}
                </StyledTableCell>
                <StyledTableCell>
                  {'Online'}
                </StyledTableCell>
            </StyledTableRow>
            ))}
          </TableBody>
          
        </Table>
      </TableContainer>
      <ConfirmationDialog
        open={openDialog}
        content={`Are you sure you really want to ${!activeStatus ? "activate" : "deactivate"} this controller?`}
        //onConfirm={handleUpdate}
        onClose={handleDialogClose}
      />
    </Box>
  );
}

export default ControllersTable; 
