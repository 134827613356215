import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, Modal, Typography, TextField, Autocomplete, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import './AddPatients.css';
import { Controller, useForm } from 'react-hook-form';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import Input from '../../../../Components/InputComponent/Input';
import ButtonComponent from '../../../../Components/ButtonComponent/ButtonComponent';
import { GetApiParam, postApi, updateApi, GetApi } from '../../../../Api/Api';
import toast from 'react-hot-toast'


const AddPatients = (props) => {
  const { handleClose, open, editValue, patientId, AddUpdatePatient } = props
  const [deviceList, setDeviceList] = useState([]);
  // const [deviceListFetched, setDeviceListFetched] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState({}); // previously used useDevice()
  const [selectedDeviceName, setSelectedDeviceName] = useState("");
  const [physicianData, setPhysicianData] = useState([]);
  const [selectedPhysician, setSelectedPhysician] = useState({});


  const user = JSON.parse(localStorage.getItem('user')); // for differentiating between admin & physician
  // physician role ID = 0
  // admin role ID = 1
  // console.log("user data: ", user);
  const isAdmin = user.role_id === 1 ? true : false;

  // display controller_name on dropdown and when clicked, add controller_id to patient
 
  const style = {
    position: 'absolute',
    top: '50%',
    left: 15,
    right: 15,
    transform: 'translateY(-50%)',
    maxWidth: 1000,
    color: '#ffffff',
    boxShadow: 24,
    margin: '1rem auto',
    maxHeight: "100%"
  };

  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm()

  // only allow devices that have not been assigned yet
  const fetchDevices = async () => {
    const controllerEndpoint = '/controllers'
    try {
      const response = await GetApi(controllerEndpoint);
      console.log("Data: ", response.data.data);
      const responseData = response.data.data;
      setDeviceList(responseData);
      // setDeviceListFetched(true);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  }

  const fetchPhysicians = async () => {
    const endpoint = '/physicians';

    try {
      const response = await GetApi(endpoint);
      console.log('All Physicians Data: ', response.data.data);
      const responseData = response.data.data;
      setPhysicianData(responseData);
    } catch (error) {
      console.error('Error fetching physician details: ', error);
    }
  }

  useEffect(() => {
    fetchDevices();
    fetchPhysicians();
  }, []);

  // sets it so that selectedDevice will be the full device instead of just the name
  const handleSelectedDevice = (event) => {
    if (event.target.value !== "") {
      const device = deviceList.find(d => d.controller_name === event.target.value);
      setSelectedDevice(device);
      setSelectedDeviceName(device.controller_name);
    }
    else {
      setSelectedDevice("");
    }
  }

  const handleSelectedPhysician = (event) => {
    if (event.target.value !== "") {
      const physician = physicianData.find(p => p.last_name === event.target.value);
      setSelectedPhysician(physician);
    }
    else {
      setSelectedPhysician("");
    }
  }


  useEffect(() => {
    if (editValue) {

      const fetchPatientDetails = async () => {
        const endpoint = '/patient/'
        
        try {
          const response = await GetApiParam(endpoint, { id: patientId })
          
          console.log('patient details: ', response.data.data[0])
          const patientDetails = response.data.data[0];
          setValue('title', patientDetails.title);
          setValue('patient_name', patientDetails.patient_name);
          setValue('patient_nick_name', patientDetails.patient_nick_name);
          setValue('patient_code', patientDetails.patient_code);
          setValue('reference_name', patientDetails.reference_name);
          setValue('age', patientDetails.age);
          setValue('gender', patientDetails.gender);
          setValue('weight', patientDetails.weight);
          setValue('height', patientDetails.height);
          setValue('phone_no', patientDetails.phone_no);
          setValue('email_id', patientDetails.email_id);
          setValue('disease', patientDetails.disease);
          setValue('physician_name', patientDetails.physician_name);
          setValue('controller_id', patientDetails.controller_id);
          setValue('physician_id', patientDetails.physician_id);

          // for making dropdown show correct associated controller/physician instead of 'None'
          const selectDropdownDevice = deviceList.find(d => d.id === patientDetails.controller_id);
          const selectDropdownPhysician = physicianData.find(p => p.last_name === patientDetails.physician_name);
          console.log('selected device (edit modal): ', selectDropdownDevice);
          console.log('selected device name: ', selectDropdownDevice.controller_name);
          setSelectedDevice(selectDropdownDevice);
          setSelectedDeviceName(selectDropdownDevice.controller_name);
          setSelectedPhysician(selectDropdownPhysician);
       
        } catch (error) {
          console.error('Error fetching patient details:', error);
        }
      };
      fetchPatientDetails();
    }
  }, [editValue, patientId, setValue]);

  const genderOptions = ['Male', 'Female', 'Other'];
  
  const weightOptions = [ '30', '40', '50', '60', '70', '80', '90', '110', '120', '130', '140', '150', '160', '170', '180', '190', '200', '210', '220', '230', '240', '250'];

  const heightOptions = [ '20', '30', '40', '50', '60', '70', '80', '90', '100'];

  // const goalOptions = ['Sleep Period', 'temperature', 'humidity']

  const userDetails = JSON.parse(localStorage.getItem("user"));

  const onSubmit = async () => {
    console.log('in onSubmit');

    const data = getValues();

    // console.log('form submitted with data: ', data);

    const endpoint = editValue ? `/patient/${patientId}` : '/patient';
    const apiFunction = editValue ? updateApi : postApi;

    // console.log("controller name: ", selectedDevice.controller_name);
    let controllerID = null;
    if (selectedDevice) {
      controllerID = selectedDevice.id;
    }

    
    let physician = null;
    let physicianID = null
    if (selectedPhysician && isAdmin) {
      physician = selectedPhysician.last_name
      physicianID = selectedPhysician.id
    }
    
    else {
      console.log('admin status: ', !isAdmin);
      physician = user.last_name;
      physicianID = user.id;
    }


    const formData = {
      ...data,
      controller_id: controllerID, 
      user_id: userDetails.id,
      physician_name: physician,
      physician_id: physicianID
    }

    try {
      const responseData = await apiFunction(endpoint, editValue ? { ...formData, id: patientId } : formData);
      console.log('API response:', responseData.data);
      if(responseData?.status === 200){
        if (editValue ? responseData.data : responseData.data.data) {
          reset();
          handleClose();
          toast.success(editValue ? "Patient Updated" : "Patient Added");
          AddUpdatePatient()
        } else {
          toast.error(editValue ? "Patient Updated Failed" : "Patient Name already exists.");
        }
      } else {
        toast.error(responseData?.data?.error);
      }
    } catch (error) {
      console.log('Error:', error);
    }
  };

  /* can be used to remove persisting toast error message, but will persist across one page 
  if you do not wait for message to be dismissed on its own */
  // useEffect(() => {
  //   return () => {
  //     toast.remove();
  //   };
  // }, []);

 
  return (
    <Box >
      <Modal
        open={open}
        onClose={handleClose}
        className="profile-modal"
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} >
          <Box className="modal-shadow">
            <Box className="modal-header" >
              <Typography id="modal-modal-title" className='text-primery' variant="h4" component="h4">
                Profile Update
              </Typography>
              <Button onClick={handleClose}>
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                  <path opacity="0.2" d="M32.0002 3.138L28.8632 0L16.4242 12.439L3.98517 0L0.847168 3.138L13.2862 15.577L0.847168 28.016L3.98517 31.154L16.4242 18.714L28.8632 31.153L32.0012 28.015L19.5612 15.577L32.0002 3.138Z" fill="white" />
                </svg>
              </Button>
            </Box>
            {/* form submit box */}
            <Box className="modal-body" id="modal-modal-description" component="form" >
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6}>
                    <Box className="form-group select-form-group">
                      <FormControl fullWidth>
                        <InputLabel shrink className='input-label'>Title</InputLabel>
                        <Controller
                          name="title"
                          control={control}
                          defaultValue=""
                          render={({ field }) => (
                            <Select
                              {...field}
                              displayEmpty
                              onChange={(e) => {
                                setValue('title', e.target.value);
                                field.onChange(e);
                              }}
                            >
                              <MenuItem value=""><span>Mr / Ms</span></MenuItem>
                              <MenuItem value="Mr">Mr.</MenuItem>
                              <MenuItem value="Ms">Miss</MenuItem>
                            </Select>
                          )}
                          rules={{ required: 'Title is required' }}
                        />
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Box className="form-group">
                      <Input
                        label="Patient First Name"
                        placeholder="Lindsey"
                        type="text"
                        name="patient_name"
                        requiredField="requiredField"
                        register={register}
                        validationRules={{
                          required: 'Patient Name is required',
                        }} />
                      {errors.patient_name && <p className="error-message">{errors.patient_name.message}</p>}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Box className="form-group">
                      <Input
                        label="Patient Nick Name"
                        placeholder="Pitts"
                        type="text"
                        name="patient_nick_name"
                        requiredField="requiredField"
                        register={register}
                        validationRules={{
                          required: 'Patient Nick Name is required',
                        }}
                      />
                      {errors.patient_nick_name && <p className="error-message">{errors.patient_nick_name.message}</p>}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Box className="form-group">
                      <Input
                        label="Patient Code"
                        placeholder="409866"
                        type="number"
                        name="patient_code"
                        requiredField="requiredField"
                        register={register}
                        validationRules={{
                          required: 'Patient Code is required',
                        }}
                      />
                      {errors.patient_code && <p className="error-message">{errors.patient_code.message}</p>}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Box className="form-group">
                      <Input
                        label="Reference Name"
                        placeholder="Lindsey"
                        type="text"
                        name="reference_name"
                        requiredField="requiredField"
                        register={register}
                        validationRules={{
                          required: 'Reference Name is required',
                        }} />
                      {errors.reference_name && <p className="error-message">{errors.reference_name.message}</p>}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={2}>
                    <Box className="form-group">
                      <Input
                        label="Age"
                        placeholder="Age"
                        type="numer"
                        name="age"
                        requiredField="requiredField"
                        register={register}
                        validationRules={{
                          required: 'Age is required',
                        }}
                      />
                      {errors.age && <p className="error-message">{errors.age.message}</p>}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Box className="form-group">
                      <Box className="select-opation--wrapper">
                        <FormControl variant="standard">
                          <InputLabel shrink className='input-label'>Gender</InputLabel>
                          <Controller
                            name="gender" 
                            control={control}
                            defaultValue="" 
                            render={({ field }) => (
                              <Autocomplete
                                {...field}
                                disablePortal
                                id="combo-box-demo"
                                options={genderOptions}
                                getOptionLabel={(option) => option}
                                sx={{ width: '100%' }}
                                renderInput={(params) => <TextField {...params} />}
                                onChange={(_, value) => setValue('gender', value)}
                              />
                            )}
                          />
                        </FormControl>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <Box className="form-group">
                      <Box className="select-opation--wrapper">
                        <FormControl variant="standard">
                          <InputLabel shrink className='input-label'>Weight (lbs)</InputLabel>
                          <Controller
                            name="weight" 
                            control={control}
                            defaultValue="" 
                            render={({ field }) => (
                              <Autocomplete
                                {...field}
                                disablePortal
                                id="combo-box-demo"
                                options={weightOptions}
                                getOptionLabel={(option) => option} // Specify how to get the label
                                sx={{ width: '100%' }}
                                renderInput={(params) => <TextField {...params} />}
                                onChange={(_, value) => setValue('weight', value)}
                              />
                            )}
                          />
                        </FormControl>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <Box className="form-group">
                      <Box className="select-opation--wrapper">
                        <FormControl variant="standard">
                          <InputLabel shrink className='input-label'>Height (in)</InputLabel>
                          <Controller
                            name="height"
                            control={control}
                            defaultValue="" 
                            render={({ field }) => (
                              <Autocomplete
                                {...field}
                                disablePortal
                                id="combo-box-demo"
                                options={heightOptions}
                                getOptionLabel={(option) => option} // Specify how to get the label
                                sx={{ width: '100%' }}
                                renderInput={(params) => <TextField {...params} />}
                                onChange={(_, value) => setValue('height', value)}
                              />
                            )}
                          />

                        </FormControl>

                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Box className="form-group">
                      <Box className="cstm-phone--input input-box">
                        <InputLabel className="cstm-label">Phone Number</InputLabel>
                        <FormControl variant="standard">
                          <Controller
                            name="phone_no" 
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                              <PhoneInput
                                {...field}
                                country={'us'}
                                label="Phone number"
                                placeholder="000-000-0000" 
                              />
                            )}
                          />
                        </FormControl>

                        {errors.phone_no && <p className="error-message">{errors.phone_no.message}</p>}
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Box className="form-group">
                      <Input
                        label="Email"
                        placeholder="Enter your Email"
                        type="email"
                        name="email_id"
                        register={register}
                        requiredField="requiredField"
                        validationRules={{
                          required: 'Email is required.',
                          pattern: {
                            value: /^\S+@\S+$/i,
                            message: 'Invalid email format. Please enter a valid email address.',
                          },
                        }}
                      />
                      {errors.email && (
                        <p className="error-message">{errors.email_id.message}</p>
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Box className="form-group">
                      <Input
                        label="Disease"
                        placeholder="Disease"
                        type="text"
                        name="disease"
                        requiredField="requiredField"
                        register={register}
                        validationRules={{
                          required: 'Disease is required',
                        }}
                      />
                      {errors.disease && <p className="error-message">{errors.disease.message}</p>}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Box className="form-group">
                      <Input
                        label="Address"
                        placeholder="Address"
                        type="text"
                        name="address"
                        requiredField="requiredField"
                        register={register}
                        validationRules={{
                          required: 'Address is required',
                        }}
                      />
                      {errors.address && <p className="error-message">{errors.address.message}</p>}
                    </Box>
                  </Grid>
                  {/* controller dropdown */}
                  
                  <Grid item xs={12} sm={6} md={6}> 
                    <Box className="form-group">
                      <InputLabel shrink className='input-label'> Select Controller </InputLabel>
                      <Select value={selectedDeviceName} 
                              onChange={handleSelectedDevice} 
                              displayEmpty
                              inputProps={{ 'aria-label': 'Select Data Type' }}
                              className="dropdown"
                      >
                        <MenuItem value=""> None </MenuItem>
                        {deviceList.map((device) => (
                          <MenuItem key={device.id} value={device.controller_name}> 
                            {device.controller_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </Box>
                  </Grid>
                  {/* Physician Dropdown for Admins */}
                  {isAdmin && (
                    <Grid item xs={12} sm={6} md={6}> 
                      <Box className="form-group">
                        <InputLabel shrink className='input-label'> Select Physician </InputLabel>
                        <Select value={selectedPhysician.last_name || ""}
                                onChange={handleSelectedPhysician}
                                displayEmpty
                                inputProps={{ 'aria-label': 'Select Physician' }}
                                className="dropdown"
                        >
                          <MenuItem value=""> None </MenuItem>
                          {physicianData.map((physician) => (
                            <MenuItem key={physician.id} value={physician.last_name}> 
                              <span> Dr.  </span> {physician.last_name}
                            </MenuItem>
                          ))}
                        </Select>
                      </Box>
                    </Grid>
                  )}
                  {/* <Grid item xs={12} sm={6} md={6}>
                    <Box className="form-group">
                      <Box>
                        <Box className="cstm-file--input">
                          <Input
                            label="Profile photo"
                            placeholder="Profile photo"
                            type="file"
                            name="profilePhoto"
                            requiredField="requiredField"
                            register={register}
                            validationRules={{
                              required: 'Profile Photo is required',
                            }}
                          />
                          <Button className="file-upload--btn">UPLOAD</Button>
                        </Box>
                        {errors.profilePhoto && <p className="error-message">{errors.profilePhoto.message}</p>}
                      </Box>
                    </Box>
                  </Grid> */}
                  {/* <Grid item xs={12} sm={6} md={6}>
                    <Box className="form-group">
                      <Box className="select-opation--wrapper">

                        <FormControl variant="standard">
                          <InputLabel shrink className='input-label'>Set Goal</InputLabel>
                          <Controller
                            name="goal" // Name of the field in your form data
                            control={control}
                            defaultValue="" // Set the default value if needed
                            render={({ field }) => (
                              <Autocomplete
                                {...field}
                                disablePortal
                                id="combo-box-demo"
                                options={goalOptions}
                                getOptionLabel={(option) => option} // Specify how to get the label
                                sx={{ width: '100%' }}
                                renderInput={(params) => <TextField {...params}/>}
                                onChange={(_, value) => setValue('goal', value || '')} // Set the  value to 'Goal' in your form data
                              />
                            )}
                          />
                        </FormControl>
                      </Box>

                    </Box>
                  </Grid> */}
                  <Grid item xs={12} sm={12} md={12}>
                    <Box className="form-group">
                      <Box className="btn-center">
                        <ButtonComponent buttonText="Add patient" buttonVariant="contained" onClick={onSubmit}/>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>


    </Box>
  )
}

export default AddPatients