import { Box } from "@mui/material";
import { useCallback, useEffect, useRef } from "react";
import {
  EVENT_SIDE_PADDING,
  GOAL_SQUARE_SIZE,
  EVENT_COLORS,
  EVENT_HEIGHT,
  Y_PADDING_TOP,
  Y_PADDING_BOTTOM,
} from "./constants";

const EventChart = ({ data }) => {
  const canvasRef = useRef(null);
  const containerRef = useRef(null);

  const { label, goal, xs, ys } = data;

  const draw = useCallback(
    (ctx) => {
      // Clear the canvas before each redraw
      ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);

      const containerWidth = containerRef.current.clientWidth;
      const dataPointWidth = 40;
      const contentWidth = Math.max(containerWidth, xs.length * dataPointWidth);

      // Determine the maximum y value dynamically
      const maxYValue = Math.ceil(Math.max(...ys, goal));
      const yAxisStep =
        maxYValue <= 1 ? maxYValue / 6 : Math.ceil(maxYValue / 6);

      canvasRef.current.width = contentWidth;
      canvasRef.current.height =
        EVENT_HEIGHT + Y_PADDING_TOP + Y_PADDING_BOTTOM;

      const stepX = (contentWidth - 2 * EVENT_SIDE_PADDING) / (xs.length + 1);

      // Set background color
      ctx.fillStyle = EVENT_COLORS.BG2;
      ctx.fillRect(0, 0, canvasRef.current.width, canvasRef.current.height);

      const firstPointX = EVENT_SIDE_PADDING + 60;
      const lastPointX = firstPointX + (xs.length - 1) * stepX;

      // Draw goal line
      const GOAL_LINE_Y =
        Y_PADDING_TOP + (EVENT_HEIGHT - (goal / maxYValue) * EVENT_HEIGHT);
      ctx.strokeStyle = "#74FF8B";
      ctx.lineWidth = 2;
      ctx.beginPath();
      ctx.moveTo(firstPointX, GOAL_LINE_Y);
      ctx.lineTo(lastPointX, GOAL_LINE_Y);
      ctx.stroke();

      // Draw Y-Axis Line
      ctx.strokeStyle = EVENT_COLORS.Accent3;
      ctx.lineWidth = 2;
      ctx.beginPath();
      ctx.moveTo(firstPointX, Y_PADDING_TOP);
      ctx.lineTo(firstPointX, EVENT_HEIGHT + Y_PADDING_TOP);
      ctx.stroke();

      // Draw X-Axis Line
      ctx.beginPath();
      ctx.moveTo(firstPointX, EVENT_HEIGHT + Y_PADDING_TOP);
      ctx.lineTo(lastPointX, EVENT_HEIGHT + Y_PADDING_TOP);
      ctx.stroke();

      // Plot data points and lines
      ctx.strokeStyle = EVENT_COLORS.Accent3;
      ctx.lineWidth = 2;
      ctx.beginPath();
      for (let i = 0; i < xs.length; i++) {
        const x = firstPointX + i * stepX; // Calculate x for each point
        const y =
          Y_PADDING_TOP + (EVENT_HEIGHT - (ys[i] / maxYValue) * EVENT_HEIGHT);

        if (i === 0) {
          ctx.moveTo(x, y);
        } else {
          ctx.lineTo(x, y);
        }

        // Draw squares on each data point
        ctx.fillStyle = EVENT_COLORS.Accent3;
        ctx.fillRect(
          x - GOAL_SQUARE_SIZE / 2,
          y - GOAL_SQUARE_SIZE / 2,
          GOAL_SQUARE_SIZE,
          GOAL_SQUARE_SIZE
        );
      }
      ctx.stroke();

      // Draw x-axis labels (every 3rd date) and ticks
      ctx.font = "16px Maitree";
      ctx.fillStyle = EVENT_COLORS.Accent3;
      xs.forEach((date, index) => {
        const x = firstPointX + index * stepX;
        // X-Axis Ticks
        ctx.beginPath();
        ctx.moveTo(x, EVENT_HEIGHT + Y_PADDING_TOP);
        ctx.lineTo(x, EVENT_HEIGHT + Y_PADDING_TOP + 10); // Draw a small tick
        ctx.stroke();

        if (index % 3 === 0) {
          // Only draw every 3rd date
          ctx.fillText(date, x - 15, EVENT_HEIGHT + Y_PADDING_TOP + 25);
        }
      });

      // Draw y-axis values dynamically (based on maxYValue) and ticks
      ctx.font = "16px Maitree";
      ctx.fillStyle = EVENT_COLORS.Accent3;
      ctx.textAlign = "right";
      for (let i = 0; i <= maxYValue; i += yAxisStep) {
        const y =
          Y_PADDING_TOP + (EVENT_HEIGHT - (i / maxYValue) * EVENT_HEIGHT);
        // Y-Axis Ticks
        ctx.beginPath();
        ctx.moveTo(firstPointX - 10, y); // A small tick
        ctx.lineTo(firstPointX, y);
        ctx.stroke();

        // Y-Axis Values
        ctx.fillText(`${formatter.format(i)}`, EVENT_SIDE_PADDING + 50, y + 5);
      }

      // Draw vertical label for "Sleep Duration (Hrs)"
      ctx.translate(20, EVENT_HEIGHT / 2 + Y_PADDING_TOP);
      ctx.rotate(-Math.PI / 2);
      ctx.textAlign = "center";
      ctx.fillText(label, 0, 0);
      ctx.restore();
    },
    [xs, ys, label, goal]
  );

  useEffect(() => {
    if (!xs || !ys) return;
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");

    const handleResize = () => {
      draw(context);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [draw, xs, ys]);

  if (!xs || !ys) {
    return null;
  }

  return (
    <Box px={3} display="flex" alignItems="center" flex={1} position="relative">
      <Box
        mb="30px"
        ref={containerRef}
        style={{ overflowX: "auto", width: "100%" }}
      >
        <canvas ref={canvasRef} />
      </Box>
    </Box>
  );
};

export default EventChart;

const formatter = new Intl.NumberFormat("en-US", {
  style: "decimal",
  maximumFractionDigits: 2,
  minimumFractionDigits: 0,
});
