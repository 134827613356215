import React, { useEffect, useState } from 'react'
import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from '@mui/material'
import '../Profile/Profile.css'
import { Controller, useForm } from 'react-hook-form'
import Input from '../../Components/InputComponent/Input'
import ButtonComponent from '../../Components/ButtonComponent/ButtonComponent'
import { updateApi } from '../../Api/Api'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import "../../Assets/Css/Responsive.css";


const statusList = {
    "start_treatment_date": "Start Treatment Date",
    "end_treatment_date": "End Treatment Date",
};

const Prescription = () => {
    const [selectedStatus, setSelectedStatus] = useState("start_treatment_date");
    const [statusLabel, setStatusLabel] = useState("Start Treatment Date");
    const navigate = useNavigate()

    const {
        register,
        handleSubmit,
        control,
        setValue,
        formState: { errors },
    } = useForm()


    const handleChangeStatus = (event) => {
        setSelectedStatus(event.target.value);
    };

    useEffect(() => {
        setStatusLabel(statusList[selectedStatus])
    }, [selectedStatus])

    const onSubmit = async (data, event) => {
        event.preventDefault();
        console.log('Form submitted with data:', data);
        navigate('/prescription-details')
        // const endpoint = '/forgotPassword';

        // try {
        //   const responseData = await postRequest(endpoint, data);
        //   console.log('API response:', responseData);
        // } catch (error) {
        //   console.log('error:', error);
        // }
    };

    return (
        <Box sx={{ flexGrow: 1 }} component="form" onSubmit={handleSubmit(onSubmit)}>
            <Grid sx={{ mt: 4 }} container spacing={2}>
                <Grid item xs={12} sm={6} md={6}>
                    <Box className="form-group">
                        <Input
                            label={"Prescription"}
                            placeholder="Prescription"
                            type="text"
                            name="prescription"
                            requiredFeild="requiredFeild"
                            register={register}
                            validationRules={{
                                required: 'Prescription is required',
                                pattern: {
                                    value: /^[a-zA-Z\s]+$/,
                                    message: 'Prescription should contain only letters and spaces'
                                },
                                // validate: NameValidation
                            }} />
                        {errors.prescription && <p className="error-message">{errors.prescription.message}</p>}
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <Box className="form-group">
                        <Input
                            label="Next Visit Date"
                            placeholder="01/01/2015"
                            type="date"
                            name="next_visit_date"
                            register={register}
                        // validationRules={{
                        //   required: 'Middle Name is required',
                        // }} 
                        />
                        {/* {errors.next_visit_date" && <p className="error-message">{errors.next_visit_date".message}</p>} */}
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <Box className="form-group">
                        <Input
                            label="Remarks"
                            placeholder=""
                            type="text"
                            name="remarks"
                            requiredFeild="requiredFeild"
                            register={register}
                            validationRules={{
                                required: 'Remarks is required',
                                // validate: NameValidation,
                                pattern: {
                                    value: /^[a-zA-Z\s]+$/,
                                    message: 'Remarks should contain only letters and spaces'
                                },
                            }} />
                        {errors.remarks && <p className="error-message">{errors.remarks.message}</p>}
                    </Box>
                </Grid>

                <Grid item xs={12} sm={6} md={6}>
                    <Box className="form-group select-form-group">
                        <FormControl fullWidth>
                            <InputLabel shrink className='input-label'>Select Status</InputLabel>
                            <Controller
                                name="status"
                                control={control}
                                defaultValue="start_treatment_date"
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        displayEmpty
                                        onChange={(e) => {
                                            setValue('status', e.target.value);
                                            field.onChange(e);
                                        }}
                                    >
                                        {Object.entries(statusList).map(([value, label]) => (
                                            (value === 'start_treatment_date' && field.value === 'start_treatment_date') ||  
                                            value === 'end_treatment_date'
                                            ?
                                            <MenuItem key={value} value={value}>
                                                {label}
                                            </MenuItem>
                                             :
                                             null
                                        ))}
                                    </Select>
                                )}
                                rules={{ required: 'Status is required' }}
                            />
                        </FormControl>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    <Box className="form-group">
                        <Box className="btn-center">
                            <ButtonComponent type="submit" buttonText="Save" buttonVariant="contained" />
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>

    )
}

export default Prescription