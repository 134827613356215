import { Close } from "@mui/icons-material";
import { Box, IconButton, Modal, Tab, Tabs, Typography } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { useCallback, useEffect, useState } from "react";
import toast from "react-hot-toast";
import EventChart from "../../../../Components/EventChartComponent";
import { parseDate } from "../../../../utils/helpers";
import { getPatientEventGraph } from "../../../../Api/AdapterApi";

const EventModal = ({ open, onClose, event, patientId }) => {
  const [currentTab, setCurrentTab] = useState(0);

  // graph stuff
  const [data, setData] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);

  const fetchProgressData = useCallback(async () => {
    if (!event?.graphs || !startTime || !endTime || startTime >= endTime)
      return;

    const currentGraph = event?.graphs[currentTab];

    const params = {
      patient_id: patientId,
      start: parseDate(startTime),
      end: parseDate(endTime),
      event_type_id: event?.category,
      graph_id: currentGraph?.ty,
    };
    const response = await getPatientEventGraph(params);

    if (response?.xs) {
      const parsed = parseData(currentGraph, response);
      setData(parsed);
    }
  }, [
    event?.graphs,
    event?.category,
    startTime,
    endTime,
    currentTab,
    patientId,
  ]);

  useEffect(() => {
    fetchProgressData();
  }, [fetchProgressData]);

  if (!event?.graphs) return;
  const currentGraph = event?.graphs[currentTab];

  const onChangeTab = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const handleStartTimeChange = (date) => {
    toast.remove();
    if (endTime && date >= endTime) {
      toast.error("Start date should be before end date");
    }
    setStartTime(date);
  };

  const handleEndTimeChange = (date) => {
    toast.remove();
    if (startTime && startTime >= date) {
      toast.error("End date should be after start date");
    }
    setEndTime(date);
  };

  const tabs = event?.graphs.map((graph, index) => (
    <Tab
      label={graph.name}
      key={index}
      sx={{
        color: "#91D9B9",
        "&.Mui-selected": {
          color: "#91D9B9",
        },
      }}
    />
  ));

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={modalStyle} maxWidth={1200} borderRadius={2}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Box
            pt={1}
            px={2}
            display="flex"
            justifyContent="space-between"
            borderBottom="1px solid #598795"
          >
            <Tabs
              value={currentTab}
              onChange={onChangeTab}
              TabIndicatorProps={{
                children: <span className="MuiTabs-indicatorSpan" />,
              }}
              sx={{
                "& .MuiTabs-indicator": {
                  display: "flex",
                  justifyContent: "center",
                  backgroundColor: "transparent",
                },
                "& .MuiTabs-indicatorSpan": {
                  width: "100%",
                  backgroundColor: "#91D9B9",
                },
              }}
            >
              {tabs}
            </Tabs>
            <IconButton onClick={onClose}>
              <Close sx={{ color: "#598795" }} />
            </IconButton>
          </Box>
          <Box p={2}>
            <Box display="flex" justifyContent="flex-end" gap={2} mb={2}>
              <Box className="select-option">
                <DesktopDatePicker
                  value={startTime}
                  onChange={handleStartTimeChange}
                  disableFuture
                  format="MM-dd-yy"
                />
              </Box>
              <Box className="select-option">
                <DesktopDatePicker
                  value={endTime}
                  onChange={handleEndTimeChange}
                  disableFuture
                  format="MM-dd-yy"
                />
              </Box>
            </Box>

            <Box gap={2}>
              <Typography fontSize="20px" align="center">
                {currentGraph?.name}
              </Typography>

              {data ? <EventChart data={data} /> : <Empty />}
            </Box>
          </Box>
        </LocalizationProvider>
      </Box>
    </Modal>
  );
};

export default EventModal;

const Empty = () => {
  return (
    <Typography align="center" component="h4" sx={{ py: 3 }}>
      Select dates
    </Typography>
  );
};

const parseData = (graph, data) => {
  const parsed = {
    label: graph?.axis,
    goal: graph?.goal,
    xs: data?.xs || [],
    ys: data?.ys || [],
  };

  return parsed;
};

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "#042835",
  boxShadow: 24,
  color: "#91D9B9",
  width: 1000,
};

// const sampleGraphData = {
//   label: "Sleep Duration (Hrs)",
//   goal: 8,
//   xs: [
//     "07/01", "07/02", "07/03", "07/04", "07/05", "07/06", "07/07", "07/08", "07/09", "07/10",
//     "07/11", "07/12", "07/13", "07/14", "07/15", "07/16", "07/17", "07/18", "07/19", "07/20",
//     "07/21", "07/22", "07/23", "07/24", "07/25", "07/26", "07/27", "07/28", "07/29", "07/30",
//     "07/31", "08/01", "08/02", "08/03", "08/04", "08/05", "08/06", "08/07", "08/08", "08/09",
//     "08/10", "08/11", "08/12", "08/13", "08/14", "08/15", "08/16", "08/17", "08/18", "08/19",
//     "08/20", "08/21"
//   ],
//   ys: [
//     4, 5, 6, 5.5, 7, 6.5, 8, 8.5, 9, 8.8,
//     8.2, 7.9, 9.1, 8.3, 8.7, 7.5, 6, 5, 6.5, 7,
//     7.5, 7.2, 8.4, 8.9, 9.5, 9.2, 9.8, 10, 9.9, 8.7,
//     8.3, 8, 7.8, 7.6, 7.9, 8.5, 9, 9.3, 9.4, 9.1,
//     8.7, 8.2, 7.9, 7.8, 8.3, 8.6, 8.9, 9.1, 9.5, 9.2
//   ]
// };
