import React, { useEffect, useState } from 'react'
import { Box, Button, Card, CardContent, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from '@mui/material'
import '../Profile/Profile.css'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import "../../Assets/Css/Responsive.css";

const PrescriptionDetails = () => {

    const navigate = useNavigate()

    return (
        <Box sx={{ flexGrow: 1 }} >
            <Card>
                <CardContent>
                    <Typography variant="h5" component="div">
                        Patient Name: percy 
                    </Typography>
                    <Typography variant="h5" color="textSecondary">
                        Prescription: gfdgfdgf
                    </Typography>
                    <Typography variant="h5" color="textSecondary">
                        Next Visit Date: "25-05-2024"
                    </Typography>
                    <Typography variant="h5" color="textSecondary">
                        Remarks: None
                    </Typography>
                    <Typography variant="h5" color="textSecondary">
                        Status: first treatment 
                    </Typography>
                </CardContent>
            </Card>
        </Box>

    )
}

export default PrescriptionDetails