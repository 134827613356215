import { Box, Typography } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";

import {
  EVENT_LINE_PADDING,
  TIMELINE_TOP_PADDING,
  TIMELINE_BOTTOM_PADDING,
  TIMELINE_SIDE_PADDING,
  BASELINE_HEIGHT,
  EVENT_LINE_HEIGHT,
  TIMELIME_AXIS_THICKNESS,
  TIMELINE_TICK_THICKNESS,
  TIMELINE_TICK_HEIGHT,
  TIMELINE_TICK_LABEL_SPACING,
  TIMELINE_EVENT_OUTLINE_THICKNESS,
  TIMELINE_TOOLTIP_OUTLINE_THICKNESS,
  TIMELINE_TOOLTIP_PADDING,
  TIMELINE_TIME_OFFSET_X,
  TIMELINE_TIME_OFFSET_Y,
  TIMELINE_MIN_X,
  TIMELINE_COLORS,
} from "./constants";

const TimelineChart = ({ events, timeline_start_hour, timeline_hours }) => {
  const canvasRef = useRef(null);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    if (!events || events.length === 0) return;

    const handleMouseMove = (event) => {
      const canvas = canvasRef.current;
      const rect = canvas.getBoundingClientRect();
      setMousePosition({
        x: event.clientX - rect.left,
        y: event.clientY - rect.top,
      });
    };

    const canvas = canvasRef.current;
    canvas.addEventListener("mousemove", handleMouseMove);

    // Cleanup event listener on component unmount
    return () => {
      canvas.removeEventListener("mousemove", handleMouseMove);
    };
  }, [events]);

  const draw = useCallback(
    (ctx) => {
      canvasRef.current.width =
        document.getElementById("timeline-container").clientWidth;

      // --- Timeline initialization code ---
      // Sort events in start time order
      events.sort((a, b) => a.start - b.start);

      // Assign rows to events
      let maxRow = 0;
      const activeEvents = [];
      for (let i = 0; i < events.length; i++) {
        const event = events[i];
        if (event.text === "Awake" || event.text === "Asleep") {
          // The Awake & Asleep events will always be mutually exclusive & exist on the baseline
          event.row = 0;
        } else {
          // Other events will be automatically stacked above the baseline

          // Update the activeEvents list based on the start time of this event
          // and add this event to an empty row if there is one
          let rowAssigned = false;
          for (let j = 0; j < activeEvents.length; j++) {
            // Check if this event has ended
            if (
              activeEvents[j] !== null &&
              activeEvents[j].end <= event.start
            ) {
              activeEvents[j] = null;
            }

            // Check if the row is empty
            if (activeEvents[j] === null) {
              event.row = j + 1;
              activeEvents[j] = event;
              rowAssigned = true;
              break;
            }
          }

          // No empty row was found, add the event to a new row
          if (!rowAssigned) {
            event.row = activeEvents.length + 1;
            activeEvents.push(event);
          }

          // Update the maxRow
          maxRow = Math.max(maxRow, event.row);
        }
      }

      const TIMELINE_HEIGHT =
        TIMELINE_BOTTOM_PADDING +
        EVENT_LINE_PADDING +
        BASELINE_HEIGHT +
        (EVENT_LINE_PADDING + EVENT_LINE_HEIGHT) * maxRow +
        TIMELINE_TOP_PADDING;

      canvasRef.current.height = TIMELINE_HEIGHT;

      ctx.font = '14px "Arial"';
      const TIMELINE_WIDTH = canvasRef.current.width - 2 * TIMELINE_MIN_X;

      const timelineMouseX = mousePosition.x;
      const timelineMouseY = mousePosition.y;

      ctx.fillStyle = TIMELINE_COLORS.BG2;
      ctx.fillRect(0, 0, TIMELINE_WIDTH, TIMELINE_HEIGHT);

      const TIMELINE_AXIS_Y = TIMELINE_HEIGHT - TIMELINE_BOTTOM_PADDING;
      const BASELINE_Y = TIMELINE_AXIS_Y - EVENT_LINE_PADDING - BASELINE_HEIGHT;
      const TIMELINE_AXIS_WIDTH = TIMELINE_WIDTH - 2 * TIMELINE_SIDE_PADDING;

      ctx.fillStyle = TIMELINE_COLORS.Accent3;
      ctx.fillRect(
        TIMELINE_SIDE_PADDING,
        TIMELINE_AXIS_Y - 0.5 * TIMELIME_AXIS_THICKNESS,
        TIMELINE_AXIS_WIDTH,
        TIMELIME_AXIS_THICKNESS
      );

      for (let i = 0; i <= timeline_hours; i++) {
        const x =
          (i / timeline_hours) * TIMELINE_AXIS_WIDTH -
          0.5 * TIMELINE_TICK_THICKNESS +
          TIMELINE_SIDE_PADDING;
        ctx.fillRect(
          x,
          TIMELINE_AXIS_Y,
          TIMELINE_TICK_THICKNESS,
          TIMELINE_TICK_HEIGHT
        );

        const hour = (i + timeline_start_hour) % 24;
        let labelText = "";
        if (hour === 0) {
          labelText = `12 AM`;
        } else if (hour > 12) {
          labelText = `${(hour - 12).toString().padStart(2, "0")} PM`;
        } else {
          labelText = `${hour.toString().padStart(2, "0")} AM`;
        }

        const textMetrics = ctx.measureText(labelText);
        ctx.fillText(
          labelText,
          x - 0.5 * textMetrics.width,
          TIMELINE_AXIS_Y +
            TIMELINE_TICK_HEIGHT +
            textMetrics.actualBoundingBoxAscent +
            TIMELINE_TICK_LABEL_SPACING
        );
      }

      for (let i = 0; i < events.length; i++) {
        const event = events[i];

        const minY =
          BASELINE_Y - (EVENT_LINE_PADDING + EVENT_LINE_HEIGHT) * event.row;
        const maxY =
          minY + (event.row === 0 ? BASELINE_HEIGHT : EVENT_LINE_HEIGHT);

        const minX =
          TIMELINE_SIDE_PADDING +
          event.start * (TIMELINE_AXIS_WIDTH / timeline_hours);
        const maxX =
          TIMELINE_SIDE_PADDING +
          event.end * (TIMELINE_AXIS_WIDTH / timeline_hours);

        if (
          minX < timelineMouseX &&
          timelineMouseX < maxX &&
          minY < timelineMouseY &&
          timelineMouseY < maxY
        ) {
          ctx.fillStyle = TIMELINE_COLORS.Accent2;
          ctx.fillRect(minX, minY, maxX - minX, maxY - minY);

          ctx.fillStyle = event.color;
          ctx.fillRect(
            minX + TIMELINE_EVENT_OUTLINE_THICKNESS,
            minY + TIMELINE_EVENT_OUTLINE_THICKNESS,
            maxX - minX - 2 * TIMELINE_EVENT_OUTLINE_THICKNESS,
            maxY - minY - 2 * TIMELINE_EVENT_OUTLINE_THICKNESS
          );
        } else {
          ctx.fillStyle = event.color;
          ctx.fillRect(minX, minY, maxX - minX, maxY - minY);
        }
      }

      const TIMELINE_MIN_MOUSE_X = TIMELINE_SIDE_PADDING;
      const TIMELINE_MAX_MOUSE_X = TIMELINE_WIDTH - TIMELINE_SIDE_PADDING;
      if (
        TIMELINE_MIN_MOUSE_X <= timelineMouseX &&
        timelineMouseX <= TIMELINE_MAX_MOUSE_X &&
        0 <= timelineMouseY &&
        timelineMouseY <= TIMELINE_HEIGHT
      ) {
        ctx.fillStyle = TIMELINE_COLORS.Accent2;
        ctx.fillRect(
          timelineMouseX - 0.5 * TIMELIME_AXIS_THICKNESS,
          0,
          TIMELIME_AXIS_THICKNESS,
          TIMELINE_AXIS_Y
        );

        const mouseTime =
          (timeline_hours * (timelineMouseX - TIMELINE_MIN_MOUSE_X)) /
            TIMELINE_AXIS_WIDTH +
          timeline_start_hour;
        const hour = Math.floor(mouseTime) % 24;
        const min = Math.floor(60 * (mouseTime % 1))
          .toString()
          .padStart(2, "0");
        let mouseTimeLabel = "";
        if (hour === 0) {
          mouseTimeLabel = `12:${min} AM`;
        } else if (hour > 12) {
          mouseTimeLabel = `${(hour - 12)
            .toString()
            .padStart(2, "0")}:${min} PM`;
        } else {
          mouseTimeLabel = `${hour.toString().padStart(2, "0")}:${min} AM`;
        }

        const textMetrics = ctx.measureText(mouseTimeLabel);

        ctx.fillStyle = TIMELINE_COLORS.Accent3;
        if (
          timelineMouseX + 2 * TIMELINE_TIME_OFFSET_X + textMetrics.width >
          TIMELINE_WIDTH
        ) {
          ctx.fillText(
            mouseTimeLabel,
            timelineMouseX - textMetrics.width - TIMELINE_TIME_OFFSET_X,
            TIMELINE_TIME_OFFSET_Y
          );
        } else {
          ctx.fillText(
            mouseTimeLabel,
            timelineMouseX + TIMELINE_TIME_OFFSET_X,
            TIMELINE_TIME_OFFSET_Y
          );
        }
      }

      ctx.setLineDash([]);
      ctx.lineWidth = TIMELINE_TOOLTIP_OUTLINE_THICKNESS;
      for (let i = 0; i < events.length; i++) {
        const event = events[i];

        const minY =
          BASELINE_Y - (EVENT_LINE_PADDING + EVENT_LINE_HEIGHT) * event.row;
        const maxY =
          minY + (event.row === 0 ? BASELINE_HEIGHT : EVENT_LINE_HEIGHT);

        const minX =
          TIMELINE_SIDE_PADDING +
          event.start * (TIMELINE_AXIS_WIDTH / timeline_hours);
        const maxX =
          TIMELINE_SIDE_PADDING +
          event.end * (TIMELINE_AXIS_WIDTH / timeline_hours);

        if (
          minX < timelineMouseX &&
          timelineMouseX < maxX &&
          minY < timelineMouseY &&
          timelineMouseY < maxY
        ) {
          const textMetrics = ctx.measureText(event.text);
          const tooltipWidth = textMetrics.width + 2 * TIMELINE_TOOLTIP_PADDING;
          const tooltipHeight =
            textMetrics.actualBoundingBoxAscent +
            textMetrics.actualBoundingBoxDescent +
            2 * TIMELINE_TOOLTIP_PADDING;

          if (timelineMouseX + tooltipWidth > TIMELINE_WIDTH) {
            ctx.fillStyle = TIMELINE_COLORS.BG2;
            ctx.fillRect(
              timelineMouseX - tooltipWidth,
              timelineMouseY - tooltipHeight,
              tooltipWidth,
              tooltipHeight
            );

            ctx.strokeStyle = TIMELINE_COLORS.Accent3;
            ctx.strokeRect(
              timelineMouseX - tooltipWidth,
              timelineMouseY - tooltipHeight,
              tooltipWidth,
              tooltipHeight
            );

            ctx.fillStyle = TIMELINE_COLORS.Accent3;
            ctx.fillText(
              event.text,
              timelineMouseX + TIMELINE_TOOLTIP_PADDING - tooltipWidth,
              timelineMouseY -
                tooltipHeight +
                textMetrics.actualBoundingBoxAscent +
                TIMELINE_TOOLTIP_PADDING
            );
          } else {
            ctx.fillStyle = TIMELINE_COLORS.BG2;
            ctx.fillRect(
              timelineMouseX,
              timelineMouseY - tooltipHeight,
              tooltipWidth,
              tooltipHeight
            );

            ctx.strokeStyle = TIMELINE_COLORS.Accent3;
            ctx.strokeRect(
              timelineMouseX,
              timelineMouseY - tooltipHeight,
              tooltipWidth,
              tooltipHeight
            );

            ctx.fillStyle = TIMELINE_COLORS.Accent3;
            ctx.fillText(
              event.text,
              timelineMouseX + TIMELINE_TOOLTIP_PADDING,
              timelineMouseY -
                tooltipHeight +
                textMetrics.actualBoundingBoxAscent +
                TIMELINE_TOOLTIP_PADDING
            );
          }
        }
      }
    },
    [
      mousePosition.x,
      mousePosition.y,
      events,
      timeline_hours,
      timeline_start_hour,
    ]
  );

  useEffect(() => {
    if (!events || events.length === 0) return;

    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");

    const handleResize = () => {
      // canvas.width = window.innerWidth;
      // canvas.height = window.innerHeight;
      draw(context);
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [draw, events]);

  if (!events || events.length === 0)
    return null;

  return (
    <Box
      display="flex"
      alignItems="center"
      id="timeline-container"
      height="100%"
      pt={2}
    >
      <canvas ref={canvasRef} />
    </Box>
  );
};

export default TimelineChart;
