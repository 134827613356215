import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Switch,
  Stack,
  Box,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import AddHospitals from "../Add hospitals/AddHospitals";
import { updateApi } from "../../../Api/Api";
import ConfirmationDialog from "../../../Components/ConfirmationDialog";
import { useSearch } from "../../../Context/SearchContext";
import toast from "react-hot-toast";


const HospitalTable = ({ hospitalData, AddUpdateHospital }) => {
  const [open, setOpen] = useState(false);
  const [editValue, setEditValue] = useState(false);
  const [activeStatus, setActiveStatus] = useState(false);
  const [hospitalId, setHospitalId] = useState(null);
  const [switchStates, setSwitchStates] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const { searchQuery } = useSearch();
  const [filteredData, setFilteredData] = useState(hospitalData);

  useEffect(() => {
    if (searchQuery) {
      const filteredResults = hospitalData.filter((item) =>
        Object.values(item).some((value) =>
          String(value).toLowerCase().includes(searchQuery.toLowerCase())
        )
      );
      setFilteredData(filteredResults);
    } else {
      setFilteredData([]);
    }
  }, [searchQuery, hospitalData]);

  const displayData = searchQuery ? filteredData : hospitalData;


  const handleOpen = (id) => {
    setHospitalId(id);
    setEditValue(true);
    setOpen(true);
  };

  const handleClose = () => {
    setHospitalId(null);
    setEditValue(false);
    setOpen(false);
  };

  const handleSwitchChange = (id, currentSwitchState) => {
    setEditValue(true);
    setHospitalId(id);
    setActiveStatus(currentSwitchState)
    setSwitchStates((prevState) => ({
      ...prevState,
      [id]: !currentSwitchState,
    }));
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleDelete = (id) => {
    setHospitalId(id);
    setEditValue(false);
    setOpenDialog(true);
  };

  const handleUpdate = async () => {
    setOpenDialog(false);
    const endpoint = `/hospital/${hospitalId}`;
    try {
      const requestData = editValue
        ? { is_actived: switchStates[hospitalId] }
        : { is_deleted: true };

      const responseData = await updateApi(endpoint, requestData);
      if (responseData.data) {
        AddUpdateHospital();
        toast.success(editValue ? "Hospital Updated" : "Hospital Deleted");
      } else {
        toast.error(editValue ? "Hospital Updated Failed" : "Hospital Deleted Failed");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <Box className="table-container">
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Hospital Name</StyledTableCell>
              <StyledTableCell>Email</StyledTableCell>
              <StyledTableCell>Phone Number</StyledTableCell>
              <StyledTableCell>Address</StyledTableCell>
              <StyledTableCell>Status</StyledTableCell>
              <StyledTableCell>Actions</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {displayData.map((hospital, index) => (
              <StyledTableRow
                key={hospital.id}
                style={{
                  borderBottom:
                    index !== hospitalData?.length - 1
                      ? "1px solid #e0e0e0"
                      : "none",
                }}
              >
                <StyledTableCell>{hospital.hospital_name}</StyledTableCell>
                <StyledTableCell>{hospital.email_id}</StyledTableCell>
                <StyledTableCell>{hospital.phone_no}</StyledTableCell>
                <StyledTableCell>{hospital.address}</StyledTableCell>
                <StyledTableCell>
                  <Switch
                    checked={hospital.is_actived}
                    className="cstm-toggle-btn"
                    onChange={() =>
                      handleSwitchChange(hospital.id, hospital.is_actived)
                    }
                  />
                </StyledTableCell>
                <StyledTableCell>
                  <Stack direction="row" gap={2}>
                    <Button
                      variant="contained"
                      color="primary"
                      className="btn-primary"
                      onClick={() => handleOpen(hospital.id)}
                    >
                      Edit
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      className="btn-delete"
                      onClick={() => handleDelete(hospital.id)}
                    >
                      Delete
                    </Button>
                  </Stack>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <ConfirmationDialog
        open={openDialog}
        // title={`${editValue ? "Update" : "Delete"} hospital`}
        content={`Are you really want to ${
          editValue ? (!activeStatus ? "active" : "deactive") : "Delete"
        } this ?`}
        onConfirm={handleUpdate}
        onClose={handleDialogClose}
      />
      <AddHospitals
        open={open}
        handleClose={handleClose}
        editValue={editValue}
        hospitalId={hospitalId}
        AddUpdateHospital={AddUpdateHospital}
      />
    </Box>
  );
};

export default HospitalTable;


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#042835",
    color: "rgb(255,255, 255, 70%)",
    borderBottom: "1px solid #021A22",
    fontFamily: "Acumin Pro",
    fontWeight: 700,
    fontSize: 16,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    backgroundColor: "#03212b",
    color: "rgb(255,255, 255, 40%)",
    borderBottom: "1px solid #021A22",
    fontFamily: "Acumin Pro",
    // fontSize: 16,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));