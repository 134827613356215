import { Box, Typography, IconButton, InputAdornment } from "@mui/material";
import Link from "@mui/material/Link";
import React, { useState } from "react";
import "./Login.css";
import "../../Assets/Css/Responsive.css";
import loginLeft from "./../../Assets/Images/Login/login-left.png";
import logo from "./../../Assets/Images/Login/logo.svg";
import Input from "../../Components/InputComponent/Input";
import ButtonComponent from "../../Components/ButtonComponent/ButtonComponent";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { postLogin } from "../../Api/Service";
import toast, { Toaster } from "react-hot-toast";
import { basePath } from "../../routes/Config";

const ResetPassword = () => {
  const navigate = useNavigate();
  const { token } = useParams();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (e) => {
    e.preventDefault();
  };

  const handleShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);
  const handleMouseDownConfirmPassword = (e) => {
    e.preventDefault();
  };

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const passwordMatchValidation = (value) => {
    const password = watch("password"); // Get the current value of the password field
    return value === password || "Passwords do not match";
  };

  const onSubmit = async (data, event) => {
    event.preventDefault();
    console.log("Form submitted with data:", data);
    const formData = { ...data, token };
    const endpoint = `/conformpassword/${token}`;
    try {
      const responseData = await postLogin(endpoint, formData);
      if (responseData.message === "Password successfully updated") {
        toast.success(responseData.message);
        navigate(`${basePath}login`);
      } else {
        toast.error(responseData.error);
      }
    } catch (error) {
      console.log("error:", error);
    }
  };

  return (
    <Box className="login-container">
      <Toaster position="top-center" reverseOrder={false} />
      <Box className="login-main">
        <Box className="left-section">
          <Box className="img-container">
            <Box className="text-container">
              <Typography component="h4" className="heading">
                Easy to observe{" "}
              </Typography>
              <Typography component="h4" className="heading">
                your daily sleep{" "}
              </Typography>
              <Typography component="h4" className="heading">
                routine.
              </Typography>
            </Box>
            <img src={loginLeft} alt="" />
          </Box>
        </Box>
        <Box
          className="right-section"
          component="form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Box className="logo-box">
            <Link href="#">
              <img src={logo} alt="Logo" />
            </Link>
          </Box>
          <Box className="login-details">
            <Box className="heading--box">
              <Typography component="h2" className="text-primery heading">
                Reset password
              </Typography>
              <Typography component="p" className="text-white">
                No worries, we'll send you reset instructions.
              </Typography>
            </Box>
          </Box>
          <Box className="form-group">
            <Box className="pass-btn-group">
              <Input
                label="New Password"
                placeholder="Enter your Password"
                requiredFeild="requiredFeild"
                type={showPassword ? "text" : "password"}
                name="password"
                register={register}
                validationRules={{
                  required: "New Password is required",
                  pattern: {
                    value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.{8,})/,
                    message: 'New Password should be of 8 in length and consist of at least 1 special character, 1 upper case character and 1 number.',
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="password"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {errors.password && (
                <p className="error-message">{errors.password.message}</p>
              )}
            </Box>
          </Box>
          <Box className="form-group">
            <Box className="pass-btn-group">
              <Input
                label="Confirm Password"
                placeholder="Enter your Password"
                requiredFeild="requiredFeild"
                type={showConfirmPassword ? "text" : "password"}
                name="confirmPassword"
                register={register}
                validationRules={{
                  required: "Confirm Password is required",
                  pattern: {
                    value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.{8,})/,
                    message: 'Confirm Password should be of 8 in length and consist of at least 1 special character, 1 upper case character and 1 number.',
                  },
                  validate: passwordMatchValidation,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="password"
                        onClick={handleShowConfirmPassword}
                        onMouseDown={handleMouseDownConfirmPassword}
                      >
                        {showConfirmPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {errors.confirmPassword && (
                <p className="error-message">
                  {errors.confirmPassword.message}
                </p>
              )}
            </Box>
          </Box>
          <Box className="form-group">
            <ButtonComponent
              type="submit"
              buttonText="Send"
              buttonVariant="contained"
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ResetPassword;
